import axios from "axios";
import { defineStore } from "pinia";

export const useUserCertificateStore = defineStore("userCertificate", {
  state: () => {
    return {};
  },
  getters: {},
  actions: {
    async getAvailableDatesRequest() {
      try {
        const url = "/certificates/available-dates";

        const response = await axios.get(url);
        return response;
      } catch (error) {
        console.error("Error getting certificates: ", error);
        throw error;
      }
    },
    async getCertificateByIdRequest(id) {
      try {
        const url = `/certificates/${id}`;

        const response = await axios.get(url);
        return response;
      } catch (error) {
        console.error("Error getting specified certificate: ", error);
        throw error;
      }
    },
    async insertCertificateRequest(payload) {
      try {
        const url = "/certificates/create-certificate";

        const response = await axios.post(url, payload);
        return response;
      } catch (error) {
        console.error("Error creating certificate: ", error);
        throw error;
      }
    },
    async markAsDownloadedRequest(certificateId) {
      try {
        const url = `/certificates/mark-as-downloaded/${certificateId}`;

        const response = await axios.get(url);
        return response;
      } catch (error) {
        console.error("Error marking as downloaded: ", error);
        throw error;
      }
    },
    async deleteCertificateRequest(certificateId, certificateName) {
      try {
        const url = `/certificates/delete/${certificateId}/${certificateName}`;

        const response = await axios.delete(url);
        return response;
      } catch (error) {
        console.error("Error deleting a certificate: ", error);
        throw error;
      }
    },
    async renameCertificateRequest(certificateId, certificateName) {
      try {
        const url = `/certificates/rename/${certificateId}/${certificateName}`;

        const response = await axios.get(url);
        return response;
      } catch (error) {
        console.error("Error renaming a certificate: ", error);
        throw error;
      }
    },
    async insertNewFolderRequest(payload) {
      try {
        const url = "/certificates/nodes/create-new-node";
        const response = await axios.post(url, payload);
        return response;
      } catch (error) {
        console.error("Error while inserting a new folder: ", error);
        throw error;
      }
    },
    async updateFolderNameRequest(payload) {
      try {
        const url = "/certificates/nodes/update-node-name";
        const response = await axios.post(url, payload);
        return response;
      } catch (error) {
        console.error("Error while changing name of the folder: ", error);
        throw error;
      }
    },
    async getTodayCertificatesRequest() {
      try {
        const url = "/certificates/get-today-certificates";
        const response = await axios.get(url);
        return response;
      } catch (error) {
        console.error("Error while getting today certificates: ", error);
        throw error;
      }
    },
    async getLast7DaysCertificatesRequest() {
      try {
        const url = `/certificates/get-last-7-days`;
        const response = await axios.get(url);
        return response;
      } catch (error) {
        console.error("Error while getting last 7 days certificates: ", error);
        throw error;
      }
    },
    async getLast30DaysCertificatesRequest() {
      try {
        const url = `/certificates/get-last-30-days`;
        const response = await axios.get(url);
        return response;
      } catch (error) {
        console.error("Error while getting last 30 days certificates: ", error);
        throw error;
      }
    },
    async getPickedDayCertificatesRequest(pickedDate) {
      try {
        const url = `/certificates/${pickedDate}`;
        const response = await axios.get(url);
        return response;
      } catch (error) {
        console.error("Error while getting certificates for picked date: ", error);
        throw error;
      }
    },
  },
});
