<template>
    <div></div>
</template>
<script>
import { useToast } from "vue-toastification";

export default {
    data() {
        return {
        }
    },
    methods: {
        triggerToast(message, position, type) {
            const toast = useToast();
            toast(message, {
                position: position,
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: "fa fa-bell",
                rtl: false,
                type: type,
            });
        }
    }
}
</script>