<template>
  <div class="container w-100 p-5 container-vh-92" style="overflow-y: scroll">
    <div class="d-flex justify-content-between flex-column mb-3">
      <div class="d-flex justify-content-between flex-row">
        <div class="d-flex justify-content-between flex-row w-100">
          <button
            class="btn btn-outline-dark border px-5"
            @click="this.btnBackClicked"
          >
            Back
          </button>
        </div>
        <div class="d-flex justify-content-between flex-row w-100">
          <button
            class="btn btn-dark text-white px-3"
            @click="this.btnVectorizerlicked"
          >
            Vectorizer
          </button>
          <button
            v-if="appplyButtonController"
            class="btn bg-dark text-white border px-5"
            @click="this.btnColorApplyClicked"
          >
            Apply
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-6 col-md-12 mb-3 mb-lg-0 p-0 pe-lg-3">
        <div class="card h-100 border">
          <div class="card-body">
            <div class="back-Ground-free-Select">
              <div
                class="palette-color-div"
                style="
                  display: flex;
                  flex-wrap: wrap;

                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  align-content: center;
                "
              >
                <div
                  style="
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    align-content: center;
                  "
                  v-if="apliedColorsPallette.length > 0"
                >
                  <div>
                    <button
                      class="btn btn-outline-dark border px-5"
                      :style="{
                        'text-transform': 'none',
                      }"
                      :disabled="loading"
                      @click="ColorpaletteBegin()"
                    >
                      Apply Pallette
                    </button>
                  </div>
                  <div
                    style="
                      display: flex;
                      flex-wrap: wrap;
                      padding: 10px;
                      min-height: 10px;
                      margin: 10px;
                      border: 2px solid #eeede9;
                      border-radius: 25px;
                      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
                        0px 2px 2px 0px rgba(0, 0, 0, 0.14),
                        0px 1px 5px 0px rgba(0, 0, 0, 0.12);
                    "
                  >
                    <div
                      style="
                        display: inline-block;
                        margin-right: 15px;
                        padding: 5px 0;
                      "
                      v-for="(color, index) in apliedColorsPallette"
                      :key="index"
                    >
                      <div style="position: relative">
                        <button
                          class="btn"
                          :style="{ 'background-color': color }"
                        >
                          {{ color }}

                          <i
                            class="bg-light bi bi-x-circle"
                            style="
                              position: absolute;
                              top: -10px;
                              right: 0;
                              width: 0.1rem;
                              height: 0.1rem;
                            "
                            @click="removeColorfromApliedPalet(index)"
                          ></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex">
              <span class="mx-1 align-self-center">Colors</span>
              <button class="btn p-0">
                <input
                  class="mx-1 p-1"
                  type="color"
                  id="color"
                  name="favcolor"
                  ref="colorPickerAddPalette"
                  v-model="colorFreeSelect"
                  @blur="colorFreeSelectfromPicker"
                />
              </button>

              <div class="text-center w-100">
                <!-- <button class="btn p-0 px-3">
                                    <i class="bi bi-arrow-counterclockwise mythIconLg" @click="undoSelection()"></i>
                                </button>-->
                <button
                  class="btn btn-outline-dark border p-0"
                  style="min-width: 40px"
                >
                  <span
                    class="text-decoration-underline mythIconMd bi bi-arrow-counterclockwise"
                    @click="resetSelection()"
                  ></span>
                </button>
                <!-- <button class="btn btn-outline-dark border p-0 ms-1" style="min-width: 40px;">
                                    <span class="mythIconMd" @click="ClearallSelection()">Clear All</span>
                                </button> -->
              </div>
            </div>
            <div class="row">
              <div class="col-4 d-flex align-items-center">
                <span>TPX</span>
              </div>
              <div class="col-8">
                <input
                  class="text-input1 form-control"
                  type="text"
                  v-model="searchTextTpx"
                  @input="searchTpx"
                  placeholder="Search TPX Code"
                />
                <ul
                  v-if="searchTextTpx"
                  class="tpx-div"
                  style="list-style-type: none"
                >
                  <li
                    v-bind:style="{ 'background-color': name }"
                    v-for="(name, value) in searchResultsTpx"
                    @click="selectColorTpx(name)"
                    :key="name"
                  >
                    {{ value }} : {{ name }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="row">
              <div class="col-4 d-flex align-items-center">
                <span>Pantone</span>
              </div>
              <div class="col-8">
                <input
                  class="text-input1 form-control"
                  type="text"
                  v-model="searchTextPant"
                  @input="searchPantone"
                  placeholder="Search Pantone Code"
                />
                <ul
                  v-if="searchTextPant"
                  class="tpx-div"
                  style="list-style-type: none"
                >
                  <li
                    v-bind:style="{ 'background-color': name }"
                    v-for="(name, value) in searchResultsPant"
                    @click="selectColorPantone(name)"
                    :key="name"
                  >
                    {{ value }} : {{ name }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="mt-2 mb-2">
              <div v-if="!userPaletteAddNameSelector" class="row">
                <div class="col-4 d-flex align-items-center">
                  <span>User Palettes</span>
                </div>

                <div class="col-4 d-flex align-items-center">
                  <button
                    class="btn btn-outline-dark border-0"
                    :style="{
                      'text-transform': 'none',
                    }"
                    :disabled="loading"
                    @click="AddUserPalette()"
                  >
                    <i class="bi bi-plus-lg mythIconLg"></i>
                  </button>
                </div>
              </div>
              <div v-else class="row">
                <div class="col-4 d-flex align-items-center">
                  <span>Create Palette</span>
                </div>

                <div class="col-4">
                  <input
                    class="form-control mt-0 w-100"
                    type="text"
                    v-model="newUserPalettename"
                    placeholder="Palette Name"
                  />
                </div>

                <div class="col-4 d-flex align-items-center">
                  <button
                    class="btn btn-outline-dark border-0"
                    :style="{
                      'text-transform': 'none',
                    }"
                    :disabled="loading"
                    @click="AddUserPalette()"
                  >
                    <i class="bi bi-patch-check mythIconLg"></i>
                  </button>
                </div>
              </div>

              <div>
                <ul class="nav nav-tabs" id="myTab2" role="tablist">
                  <li
                    class="nav-item"
                    role="presentation"
                    v-for="item in this.UserColorList"
                    :key="item"
                    :value="item"
                  >
                    <button
                      class="nav-link"
                      id="core-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#core"
                      type="button"
                      role="tab"
                      aria-controls="core"
                      aria-selected="true"
                      @click="UserPalettesetValue(item.palette_name)"
                    >
                      {{ item.palette_name }}
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="core-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#core"
                      type="button"
                      role="tab"
                      aria-controls="core"
                      aria-selected="true"
                      @click="EditUserPaletteSection()"
                    >
                      <i class="bi bi-gear mythIconLg"></i>
                    </button>
                  </li>
                </ul>
                <div
                  class="tab-content overflow-auto mh-75"
                  style="max-height: 300px"
                >
                  <div
                    class="tab-pane active"
                    id="core"
                    role="tabpanel"
                    aria-labelledby="core-tab"
                  >
                    <div
                      v-if="this.selectedUserPaletteName"
                      class="d-flex pt-3"
                    >
                      <div
                        style="
                          display: flex;
                          flex-wrap: wrap;
                          padding: 10px;
                          min-height: 10px;
                          margin: 10px;
                          border: 2px solid #eeede9;
                          border-radius: 25px;
                          box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
                            0px 2px 2px 0px rgba(0, 0, 0, 0.14),
                            0px 1px 5px 0px rgba(0, 0, 0, 0.12);
                        "
                      >
                        <div
                          v-if="userPaletteAddiconController == 'userpalettes'"
                          style="
                            display: inline-block;
                            margin-right: 10px;
                            padding: 5px 0;
                          "
                        >
                          <div
                            style="
                              display: inline-block;
                              margin-right: 10px;
                              padding: 5px 0;
                            "
                            v-for="(color, index) in paletteColorsValuesUser"
                            :key="index"
                          >
                            <button
                              class="btn"
                              @click="AddColortoselectedColors(color)"
                              :style="{ 'background-color': color }"
                            >
                              {{ color }}
                            </button>
                          </div>
                          <div>
                            <button class="btn p-0">
                              <i class="bi bi-plus-square mythIconLg"></i>
                              <input
                                class="mx-1 p-1"
                                type="color"
                                id="color"
                                name="favcolor"
                                ref="colorPickerAddPalette"
                                v-model="usercolorPaletteFreeSelect"
                                @blur="usercolorPalettefromPicker"
                              />
                            </button>
                          </div>
                        </div>

                        <div
                          v-if="
                            userPaletteAddiconController == 'EditUserPalettes'
                          "
                          style="
                            display: inline-block;
                            margin-right: 10px;
                            padding: 5px 0;
                          "
                        >
                          <div class="d-flex justify-content-between pb-3">
                            <p
                              class="w-100 align-self-center"
                              style="min-width: 100px"
                            >
                              User Palette
                            </p>
                            <label for="PaletteList"></label>
                            <select
                              name="PaletteList"
                              id="PaletteList"
                              v-model="selectedUserPaletteName"
                              @change="EditPalettefromUserPalette()"
                            >
                              <option
                                v-for="item in this.UserColorList"
                                :key="item.palette_name"
                                :value="item.palette_name"
                              >
                                {{ item.palette_name }}
                              </option>
                            </select>
                          </div>
                          <div class="text-center w-100">
                            <button
                              class="btn btn-outline-dark border-0 px-4"
                              :style="{
                                'text-transform': 'none',
                              }"
                              :disabled="loading"
                              @click="EditPalettefromUserPalette()"
                            >
                              Edit
                            </button>
                            <button
                              class="btn btn-outline-dark border-0 px-4"
                              :style="{
                                'text-transform': 'none',
                              }"
                              :disabled="loading"
                              @click="removePalettefromUserPalette()"
                            >
                              <i class="bi bi-trash"></i>
                            </button>
                          </div>
                          <div
                            v-if="
                              userPaletteEditController &&
                              selectedUserPaletteName
                            "
                            style="
                              display: inline-block;
                              margin-right: 10px;
                              padding: 5px 0;
                            "
                          >
                            <div
                              style="
                                display: flex;
                                flex-wrap: wrap;
                                padding: 10px;
                                min-height: 10px;
                                margin: 10px;
                                border: 2px solid #eeede9;
                                border-radius: 25px;
                                box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
                                  0px 2px 2px 0px rgba(0, 0, 0, 0.14),
                                  0px 1px 5px 0px rgba(0, 0, 0, 0.12);
                              "
                            >
                              <div>
                                <div
                                  style="
                                    display: inline-block;
                                    margin-right: 10px;
                                    padding: 5px 0;
                                  "
                                  v-for="(
                                    color, index
                                  ) in paletteColorsValuesUserEdit"
                                  :key="index"
                                >
                                  <button
                                    class="btn"
                                    :style="{ 'background-color': color }"
                                  >
                                    {{ color }}
                                    <i
                                      class="bg-light bi bi-x-circle"
                                      style="
                                        position: relative;
                                        top: -10px;
                                        right: -15px;
                                        width: 0.1rem;
                                        height: 0.1rem;
                                      "
                                      @click="removeColorfromUserPalette(color)"
                                    ></i>
                                  </button>
                                </div>

                                <button class="btn p-0">
                                  <i class="bi bi-plus-square mythIconLg"></i>
                                  <input
                                    class="mx-1 p-1"
                                    type="color"
                                    id="color"
                                    name="favcolor"
                                    ref="colorPickerAddPalette"
                                    v-model="usercolorPaletteFreeSelect"
                                    @blur="usercolorPalettefromPicker"
                                  />
                                </button>
                              </div>
                              <div class="text-center w-100">
                                <button
                                  class="btn btn-outline-dark border px-5"
                                  :style="{
                                    'text-transform': 'none',
                                  }"
                                  :disabled="loading"
                                  @click="EditPalettefromUserPaletteApply()"
                                >
                                  <i class="bi bi-patch-check mythIconLg"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div>
                <div class="d-flex justify-content-between pb-3">
                  <span class="w-50 align-self-center">Trend Colors</span>
                  <label for="seasons"></label>
                  <select
                    name="seasons"
                    id="seasons"
                    v-model="paletteColorsSelectorName"
                    @change="ColorPaletteinput()"
                  >
                    <option
                      v-for="Names in this.trencolorNames"
                      :key="Names"
                      :value="Names"
                    >
                      {{ Names }}
                    </option>
                  </select>
                </div>
                <div class="trendClass" v-if="paletteColorsSelectorName">
                  <div>
                    <div
                      style="
                        display: inline-block;
                        margin-right: 10px;
                        padding: 5px 0;
                      "
                      v-for="(color, index) in paletteColorsSelectorValues"
                      :key="index"
                    >
                      <button
                        class="btn"
                        @click="AddColortoselectedColors(color)"
                        :style="{ 'background-color': color }"
                      >
                        {{ color }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-6 col-md-12 p-0 mt-3 mt-xl-0">
        <div class="d-flex justify-content-center pngBG position-relative">
          <div class="position-absolute chooseBgColor">
            <input type="color" id="bgColor" name="bgColor" value="#000" />
          </div>
          <!--                    <img v-if="this.fileSrc != null" :src="this.fileSrc" class="p-4 img-color w-75" :style="{opacity : this.imgOpacity}">-->
          <canvas
            class="RenderCanvas"
            :width="canvasWidth"
            :height="canvasHeight"
            id="canvas"
            resize
            style="max-height: 400px; max-width: 400px; object-fit: contain"
          ></canvas>
          <!-- <img v-if="this.fileSrc == null" src="../../assets/img/crop-1.jpg" class="p-5 pb-2 img-color" > -->
        </div>

        <!-- <div class="px-5 pb-5">
                    <div class="d-flex justify-content-between mt-lg-5 mt-3">
                        <span>Opacity</span>
                        <span id="">
                           {{this.imgOpacity}}
                        </span>
                    </div>
                    <div class="sliderOpacityDiv">
                        <input type="range" value="100" min="0" max="100" @change="rangeValueChanged()"  class="slider w-100" id="slider-img-range">
                    </div>
                </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import colorSVG from "../../assets/js/color-svg";
export default {
  name: "ColorApp",
  props: ["fileSrc", "ColoredFileSrc", "containerTypes", "UserColorList"],
  data() {
    return {
      imgOpacity: 1,
      coloredImage: null,
      canvas: null,
      imageInfo: null,
      pathQuality: 100,
      senderPathQuality: 2,
      loading: false,
      selectedColor: this.color,
      searchTextTpx: "",
      searchTextPant: "",
      searchResultsTpx: {},
      searchResultsPant: {},
      searchResultsContTpx: null,
      searchResultsContPant: null,
      tpxData: null,
      pantonaData: null,
      width: 300,
      height: 300,
      canvasWidth: 300,
      canvasHeight: 300,
      opacity: 0,
      trendcolors: null,
      colorChanger: null,
      selectedPaletteGroup: null,
      selectedtrendcolors: null,
      trencolorNames: null,
      trencolorValues: null,
      paletteColorsSelector: null,
      originalTrendColors: null,
      userpaletteSelectorName: null,
      paletteColorsValuesUser: [],
      paletteColorsValuesUserEdit: [],
      userPaletteAddNameSelector: false,
      paletteColorsSelectorName: null,
      paletteColorsSelectorValues: null,
      apliedColorsPallette: [],
      apliedColorsPalletteforChange: [],
      trendColorsShades: null,
      showSearchbarsPantone: false,
      showSearchbarsTPX: false,
      colorFreeSelect: "#808080",
      colorFreeSelectshow: null,
      paletteColorsSelectorController: null,
      originalColoredImage: null,
      appplyButtonController: false,
      userPaletteSelector: null,
      userPaletteAddiconController: "",
      userPaletteEditController: false,
      selectedUserPaletteName: null,
      usercolorPaletteFreeSelect: "#808080",
      newUserPalettename: null,
      EditAddcolorfromOutsidecontroller: false,
      UserColorlisLenghtcontroller: false,
    };
  },
  watch: {
    ColoredFileSrc() {
      this.sleep(500).then(() => {
        this.coloredImage = this.ColoredFileSrc;
        this.CanvasRenderer();
        this.isLoading(false);
      });
    },
  },
  created() {
    this.isLoading(true);
    this.triggerColorSegmentationSelectionYes();
  },
  mounted() {
    this.isLoading(false);
    this.tpxData = require("@/assets/json/tpx.json");
    this.pantonaData = require("@/assets/json/pantone.json");
    this.trendcolors = require("@/assets/json/trendcolors.json");
    this.originalTrendColors = require("@/assets/json/trendcolorsOrigin.json");
    this.trendColorsShades = require("@/assets/json/trendcolorsShades.json");
    this.trencolorNames = this.trendcolors.names;
    this.trencolorValues = this.trendcolors.firstValues;
  },
  methods: {
    isLoading(_stat) {
      this.$emit("ChangeLoaderStatus", _stat);
    },
    rangeValueChanged() {
      this.imgOpacity = document.getElementById("slider-img-range").value / 100;
    },
    btnVectorizerlicked() {
      this.$emit("ChangeContainerType", this.containerTypes.Vectorizer);
    },
    btnColorApplyClicked() {
      this.$emit("ColorApplyChangedImage", this.coloredImage);
    },
    btnBackClicked() {
      this.$emit("ColorBackButtonClicked");
    },
    sleep(time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
    UserPalettesetValue(paletteName) {
      this.userPaletteAddiconController = "userpalettes";
      this.selectedUserPaletteName = paletteName;
      this.paletteColorsValuesUser =
        this.getPaletteColorValuefromUserPalettes(paletteName);
    },
    getPaletteColorValuefromUserPalettes(name) {
      const palette = this.UserColorList.find(
        (palette) => palette.palette_name === name
      );
      return palette ? palette.colors : null;
    },
    AddUserPaletteSection() {
      this.userPaletteAddiconController = "addUserPalettes";
    },
    AddUserPalette() {
      if (this.newUserPalettename != null) {
        this.$emit(
          "UserColorListAction",
          this.newUserPalettename,
          [],
          "insert"
        );
        this.newUserPalettename = null;
        this.userpaletteSelectorName = this.newUserPalettename;
      }
      this.userPaletteAddNameSelector = !this.userPaletteAddNameSelector;
    },
    AddUserPaletteClicked() {
      this.userPaletteAddNameSelector = !this.userPaletteAddNameSelector;
    },
    EditUserPaletteSection() {
      this.userPaletteAddiconController = "EditUserPalettes";
    },
    EditPalettefromUserPalette() {
      this.paletteColorsValuesUserEdit =
        this.getPaletteColorValuefromUserPalettes(this.selectedUserPaletteName);
      this.userPaletteEditController = !this.userPaletteEditController;
      this.EditAddcolorfromOutsidecontroller =
        !this.EditAddcolorfromOutsidecontroller;
    },
    EditPalettefromUserPaletteApply() {
      this.userPaletteEditController = !this.userPaletteEditController;
      this.EditAddcolorfromOutsidecontroller = false;
      this.paletteColorsValuesUser = this.paletteColorsValuesUserEdit;
    },
    removePalettefromUserPalette() {
      this.$emit(
        "UserColorListAction",
        this.selectedUserPaletteName,
        [],
        "delete-palette"
      );
      this.selectedUserPaletteName = null;
    },
    usercolorPalettefromPicker() {
      // add color to existing palette
      if (this.usercolorPaletteFreeSelect != null) {
        const colorlist = [];
        // for i in range colorlist yapılcak buraya
        colorlist.push(this.usercolorPaletteFreeSelect);
        this.$emit(
          "UserColorListAction",
          this.selectedUserPaletteName,
          colorlist,
          "insert"
        );
      }
      const isInclude = this.paletteColorsValuesUserEdit.includes(
        this.usercolorPaletteFreeSelect
      );
      if (!isInclude) {
        this.paletteColorsValuesUserEdit.push(this.usercolorPaletteFreeSelect);
      }
      const isInclude2 = this.paletteColorsValuesUser.includes(
        this.usercolorPaletteFreeSelect
      );
      if (!isInclude2) {
        this.paletteColorsValuesUser.push(this.usercolorPaletteFreeSelect);
      }
    },
    removeColorfromUserPalette(color) {
      // remove color from existing color palette
      const colorlist = [];
      colorlist.push(color);
      this.$emit(
        "UserColorListAction",
        this.selectedUserPaletteName,
        colorlist,
        "delete-color"
      );

      const indexToRemove = this.paletteColorsValuesUserEdit.indexOf(color);

      if (indexToRemove !== -1) {
        this.paletteColorsValuesUserEdit.splice(indexToRemove, 1);
      }
    },
    CanvasRenderer() {
      let image = new Image();
      image.src = this.coloredImage;
      image.onload = function () {};
      this.sleep(500).then(() => {
        this.initCanvas(image);
      });
    },
    initCanvas(img) {
      let cvs = document.querySelector(".RenderCanvas");
      cvs.width = img.width;
      cvs.height = img.height;
      this.imageInfo = {
        width: img.width,
        height: img.height,
        context: cvs.getContext("2d"),
      };
      this.imageInfo.context.drawImage(img, 0, 0, img.width, img.height);
      let tempCtx = document.createElement("canvas").getContext("2d");
      tempCtx.canvas.width = this.imageInfo.width;
      tempCtx.canvas.height = this.imageInfo.height;
      tempCtx.drawImage(img, 0, 0, img.width, img.height);
      this.imageInfo.data = tempCtx.getImageData(
        0,
        0,
        this.imageInfo.width,
        this.imageInfo.height
      );
      this.imageInfo.img = img;
    },
    ColorpaletteBegin() {
      let image = new Image();
      image.src = this.coloredImage;
      image.onload = function () {};
      this.sleep(500).then(() => {
        this.coloredImage = this.startApplyPaletteRequest(
          this.apliedColorsPallette
        );
      });
    },
    startApplyPaletteRequest(colorList) {
      let SelectedColorsList = [];
      let rgbList = [];
      let changerList = [];
      SelectedColorsList = colorList;
      SelectedColorsList.forEach((item) => {
        const isInclude = changerList.includes(item);
        if (!isInclude) {
          const rgbVals = this.colorChanger.hexToRgb(item);

          rgbList.push(rgbVals);
        }
      });

      let image_data = this.fileSrc;
      this.isLoading(true);
      this.appplyButtonController = true;
      this.$emit("ColorApplyPalette", image_data, rgbList);
    },
    colorFreeSelectfromPicker() {
      if (this.EditAddcolorfromOutsidecontroller) {
        this.$emit(
          "UserColorListAction",
          this.selectedUserPaletteName,
          [this.colorFreeSelect],
          "insert"
        );
        const isInclude = this.paletteColorsValuesUserEdit.includes(
          this.colorFreeSelect
        );
        if (!isInclude) {
          this.paletteColorsValuesUserEdit.push(this.colorFreeSelect);
        }
      } else {
        this.colorFreeSelectshow = this.colorFreeSelect;
        const isInclude = this.apliedColorsPallette.includes(
          this.colorFreeSelectshow
        );
        if (!isInclude) {
          this.apliedColorsPallette.push(this.colorFreeSelectshow);
        }
      }
    },
    getPaletteColorValue(name) {
      const color = this.trendcolors.colors.find((c) => c.name === name);
      return color ? color.values : null;
    },
    getPaletteColorValuefromShades(name) {
      const color = this.trendColorsShades.colors.find(
        (c) => c.name === name && c.parent === this.paletteColorsSelectorName
      );
      return color ? color.values : null;
    },
    ColorPalettesetValue(name) {
      this.paletteColorsSelectorValues =
        this.getPaletteColorValuefromShades(name);
    },
    AddColortoselectedColors(color) {
      if (this.EditAddcolorfromOutsidecontroller) {
        this.$emit(
          "UserColorListAction",
          this.selectedUserPaletteName,
          [color],
          "insert"
        );
        const isInclude = this.paletteColorsValuesUserEdit.includes(color);
        if (!isInclude) {
          this.paletteColorsValuesUserEdit.push(color);
        }
      } else {
        const isInclude = this.apliedColorsPallette.includes(color);
        if (!isInclude) {
          this.apliedColorsPallette.push(color);
        }
      }
    },
    ColorPaletteinput() {
      this.paletteColorsSelector = this.getPaletteColorValue(
        this.paletteColorsSelectorName
      );
      this.paletteColorsSelectorController = this.paletteColorsSelector;
      this.ColorPalettesetValue("Core Colours");
    },
    removeColorfromApliedPalet(index) {
      this.apliedColorsPallette.splice(index, 1);
    },
    // yapılcak
    resetSelection() {
      this.coloredImage = this.originalColoredImage;
      this.appplyButtonController = false;
      this.CanvasRenderer();
    },
    ClearallSelection() {
      this.coloredImage = this.originalColoredImage;
      this.CanvasRenderer();
      this.apliedColorsPallette = [];
      this.apliedColorsPalletteforChange = [];
    },
    triggerColorSegmentationSelectionYes() {
      this.colorChanger = new colorSVG();
      this.canvas = document.querySelector(".RenderCanvas");
      this.coloredImage = this.fileSrc;
      this.originalColoredImage = this.fileSrc;
      this.$emit("GetUserColorList");
      this.CanvasRenderer();
    },
    searchTpx() {
      this.searchResultsTpx = {};
      const searchTextTpx = this.searchTextTpx.toLowerCase();
      for (let i = 0; i < this.tpxData.names.length; i++) {
        if (this.tpxData.names[i].toLowerCase().includes(searchTextTpx)) {
          this.searchResultsTpx[this.tpxData.names[i]] = this.tpxData.values[i];
          this.searchResultsContTpx = Object.keys(this.searchResultsTpx).length;
        }
      }
      const firstSevenKeys = Object.keys(this.searchResultsTpx).slice(0, 7);
      const firstSevenResults = {};
      firstSevenKeys.forEach((key) => {
        firstSevenResults[key] = this.searchResultsTpx[key];
      });
      this.searchResultsTpx = firstSevenResults;
    },
    selectColorTpx(value) {
      if (this.EditAddcolorfromOutsidecontroller) {
        this.$emit(
          "UserColorListAction",
          this.selectedUserPaletteName,
          [value],
          "insert"
        );
        const isInclude = this.paletteColorsValuesUserEdit.includes(value);
        if (!isInclude) {
          this.paletteColorsValuesUserEdit.push(value);
        }
      } else {
        const isInclude = this.apliedColorsPallette.includes(value);
        if (!isInclude) {
          this.apliedColorsPallette.push(value);
        }
      }
    },
    searchPantone() {
      this.searchResultsPant = {};
      const searchTextPant = this.searchTextPant.toLowerCase();
      for (let i = 0; i < this.pantonaData.names.length; i++) {
        if (this.pantonaData.names[i].toLowerCase().includes(searchTextPant)) {
          this.searchResultsPant[this.pantonaData.names[i]] =
            this.pantonaData.values[i];
          this.searchResultsContPant = Object.keys(
            this.searchResultsPant
          ).length;
        }
      }
      const firstSevenKeys = Object.keys(this.searchResultsPant).slice(0, 7);
      const firstSevenResults = {};
      firstSevenKeys.forEach((key) => {
        firstSevenResults[key] = this.searchResultsPant[key];
      });
      this.searchResultsPant = firstSevenResults;
    },
    selectColorPantone(value) {
      if (this.EditAddcolorfromOutsidecontroller) {
        this.$emit(
          "UserColorListAction",
          this.selectedUserPaletteName,
          [value],
          "insert"
        );
        const isInclude = this.paletteColorsValuesUserEdit.includes(value);
        if (!isInclude) {
          this.paletteColorsValuesUserEdit.push(value);
        }
      } else {
        const isInclude = this.apliedColorsPallette.includes(value);
        if (!isInclude) {
          this.apliedColorsPallette.push(value);
        }
      }
    },
  },
};
</script>

<style>
.nav-link {
  color: gray;
}
.nav-link:active {
  color: black;
}
.pngBG {
  background-image: url("../../assets/img/transBG.jpg");
  background-repeat: repeat;
}
.chooseBgColor {
  top: 10px;
  left: 10px;
}
#bgColor {
  padding: 3px;
}
select {
  width: 100%;
  white-space: pre-wrap;
  text-overflow: ellipsis;
}
</style>
