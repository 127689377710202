import { createApp } from "vue";
import App from "./App.vue";

import router from "./router";

import store from "./store";
import { useAuthenticationStore } from "@/store/modules/auth";

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import "./assets/css/main.css";
import "./assets/css/myth.css";
import "./assets/css/custom.css";
import "./assets/css/normalize.css";

import "./assets/js/main.js";
import "./assets/js/plugins.js";
import "./assets/js/custom.js";

import "./assets/js/color-svg.js";
import "./assets/js/cropper.js";
import "./assets/js/magic-wand.js";
import "./assets/js/move.js";
import "./assets/js/pen.js";
import "./assets/js/wand-ol.js";
// import * as tf from "@tensorflow/tfjs";
// import * as nsfwjs from "nsfwjs";

const app = createApp(App);
// tf.enableProdMode();

// const modelPath = '/nsfw_models/';
// let model = await nsfwjs.load(modelPath);

// app.provide('nsfwModel', model);

app.use(store);
app.use(Toast);

const authStore = useAuthenticationStore();
const { initFetch } = authStore;

initFetch().then(() => {
  app.use(router);
  router.isReady().then(() => {
    app.mount("#app");
  });
});
