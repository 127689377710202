<template>
  <div>
    <div class="container container-vh-100">
      <div class="row">
        <div class="col-12 col-sm-6 col-md-4 py-5">
          <div
            class="d-flex justify-content-center d-sm-block position-absolute"
          >
            <img class="logo mb-50 w-100" src="@/assets/img/logo.png" />
          </div>

          <div
            class="d-flex flex-column justify-content-center container-vh-100"
          >
            <div style="margin-bottom: 20px">
              <p class="first-sentence" style="font-size: 30px">
                DESIGN PRINTING PATTERNS
              </p>
              <p class="second-sentence" style="font-size: 50px">
                <strong>LIKE NEVER BEFORE</strong>
              </p>
            </div>
            <p>Enter your username and password to sign in</p>
            <div class="position-relative d-flex">
              <input
                type="text"
                placeholder="Username"
                class=""
                id="inp-username"
                v-model="inpUsername"
              />
            </div>
            <div class="position-relative d-flex">
              <i
                id="showHide"
                @click="showPassword()"
                class="bi bi-eye position-absolute mythIconLg end-0 px-3 d-flex h-100 align-items-center"
              ></i>
              <input
                type="password"
                placeholder="Password"
                class="mt-2"
                id="inp-password"
                v-model="inpPassword"
              />
            </div>
            <span class="form-error-message" v-if="handleErrorMessages">{{
              login_error_message
            }}</span>
            <button class="login-button" @click="this.btnSubmitClicked">
              SIGN IN
            </button>
          </div>
        </div>
        <div class="d-none d-sm-block col-sm-6 col-md-8">
          <div class="row">
            <div
              class="d-none d-lg-block col-lg-4 position-relative"
              id="col-div-element-1"
            >
              <div id="div-slider-content">
                <div class="div-square">
                  <img class="w-100" src="@/assets/img/1-1.jpg" />
                </div>
                <div class="div-square">
                  <img class="w-100" src="@/assets/img/1-2.jpg" />
                </div>
                <div class="div-square">
                  <img class="w-100" src="@/assets/img/1-3.jpg" />
                </div>
              </div>
            </div>
            <div
              class="d-none d-md-block col-md-6 col-lg-4 position-relative"
              id="col-div-element-2"
            >
              <div class="div-square">
                <img class="w-100" src="@/assets/img/2-1.jpg" />
              </div>
              <div class="div-square">
                <img class="w-100" src="@/assets/img/2-2.jpg" />
              </div>
              <div class="div-square">
                <img class="w-100" src="@/assets/img/2-3.jpg" />
              </div>
            </div>
            <div
              class="d-none d-sm-block col-sm-12 col-md-6 col-lg-4 position-relative"
              id="col-div-element-3"
            >
              <div class="div-square">
                <img class="w-100" src="@/assets/img/3-1.jpg" />
              </div>
              <div class="div-square">
                <img class="w-100" src="@/assets/img/3-2.jpg" />
              </div>
              <div class="div-square">
                <img class="w-100" src="@/assets/img/3-3.jpg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import { mapActions } from "pinia";
import { useAuthenticationStore } from "@/store/modules/auth";

export default {
  data() {
    return {
      inpUsername: "",
      inpPassword: "",
      login_error_message: "",
    };
  },
  methods: {
    ...mapActions(useAuthenticationStore, ["login"]),

    showPassword() {
      let element = document.getElementById("inp-password");
      let icon = document.getElementById("showHide");
      if (element.type === "password") {
        element.type = "text";
        icon.classList.remove("bi-eye");
        icon.classList.add("bi-eye-slash");
      } else {
        element.type = "password";
        icon.classList.add("bi-eye");
        icon.classList.remove("bi-eye-slash");
      }
    },
    async btnSubmitClicked() {
      await this.login({
        username: this.inpUsername,
        password: this.inpPassword,
      })
        .then(() => {
          router.push({ name: "home" });
        })
        .catch((error) => {
          this.login_error_message = error.response.data.errors[0];
        });
    },
    handleErrorMessages() {
      if (this.login_error_message.length !== 0) {
        return true;
      }
    },
  },
  watch: {
    inpUsername() {
      this.login_error_message = "";
    },
    inpPassword() {
      this.login_error_message = "";
    },
  },
  mounted() {
    let colElementOne = document.querySelector("#col-div-element-1");
    let colElementTwo = document.querySelector("#col-div-element-2");
    let colElementThree = document.querySelector("#col-div-element-3");

    let colElementOneTop = colElementOne.getBoundingClientRect().top;
    let colElementTwoTop = colElementTwo.getBoundingClientRect().top;
    let colElementThreeTop = colElementThree.getBoundingClientRect().top;

    setInterval(() => {
      if (colElementOneTop > window.innerHeight) {
        colElementOneTop = -window.innerHeight;
      }
      if (colElementTwoTop > window.innerHeight) {
        colElementTwoTop = -window.innerHeight;
      }
      if (colElementThreeTop > window.innerHeight) {
        colElementThreeTop = -window.innerHeight;
      }

      colElementOneTop += 0.5;
      colElementTwoTop += 0.5;
      colElementThreeTop += 0.5;

      colElementOne.style.top = colElementOneTop + "px";
      colElementTwo.style.top = colElementTwoTop + "px";
      colElementThree.style.top = colElementThreeTop + "px";
    }, 2);
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css?family=Poppins|Oswald");

.div-square img {
  object-fit: contain;
}

.first-sentence {
  font-family: "Poppins", sans-serif;
  margin-bottom: -10px;
}

.second-sentence {
  font-family: "Oswald", sans-serif;
}

.form-error-message {
  color: red;
  font-size: 13px;
  text-align: left;
}
</style>
