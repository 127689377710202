<template>
  <div class="modal fade" id="tdplModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 v-if="langStatus" class="modal-title fs-5" id="staticBackdropLabel">
            Onay Belgeleri
          </h1>
          <h1 v-if="!langStatus" class="modal-title fs-5" id="staticBackdropLabel">
            Confirmation Documents
          </h1>
          <ul class="nav justify-content-end">
            <li class="nav-item">
              <button class="nav-link active" @click="langStatus = true">
                TR
              </button>
            </li>
            <li class="nav-item">
              <button class="nav-link" @click="langStatus = false">ENG</button>
            </li>
          </ul>
        </div>
        <div v-if="langStatus" class="tr-content">
          <div style="padding: 1rem">METİN</div>
          <ol v-if="langStatus" class="nav flex-column" style="margin-left: 45px">
            <li class="nav-item">
              <a class="nav-link" href="./confirmations/KVKK.docx">KVKK Aydınlatma Metni</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="./confirmations/Çerez Politikası.docx">Çerez Politikası</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="./confirmations/Gizlilik Sözleşmesi.docx">Gizlilik Sözleşmesi</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="./confirmations/Ticari Elektronik İleti Onay Metni.docx">Ticari Elektronik Onay
                Metni</a>
            </li>
          </ol>
        </div>
        <div v-if="!langStatus" class="eng-content">
          <div style="padding: 1rem">TEXT</div>
          <ol class="nav flex-column" style="margin-left: 45px">
            <li class="nav-item">
              <a class="nav-link" href="./confirmations/Privacy Notice On Protect Of Personal Data.docx">Privacy Notice On
                Protect Of Personal Data</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="./confirmations/Cookie Policy.docx">Cookie Policy</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="./confirmations/Mutual Non-Disclosure Agreement.docx">Mutual Non-Disclosure
                Agreement</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="./confirmations/Consent For Commercial Electronic Messages.docx">Consent For
                Commercial Electronic Messages</a>
            </li>
          </ol>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="declineTdpl">
            Decline
          </button>
          <button type="button" class="btn btn-primary" @click="acceptTdpl">
            Accept
          </button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="imageList.length !== 0">
    <!-- <input
      autocomplete="off"
      type="text"
      id="Labels"
      class="filter-input"
      placeholder="Search and Select Labels"
      v-model="labelName"
      @input="handleFilter"
      @click="
        handleFocus(true);
        prepareLabels();
      "
    />
    <button class="filter-button" @click="handleFilterButton">Filter</button> -->
    <div class="datalist" :style="{
      opacity: isFocused ? '1' : '0',
      transform: isFocused ? 'translateY(0)' : 'translateY(40px)',
      boxShadow: isFocused ? ' 0 0 10px 2px #ced4da' : 'none',
      pointerEvents: isFocused ? 'auto' : 'none',
    }" v-click-outside="onClickOutside">
      <div class="option-label" v-for="(label, idx) in labelNameArray" @click="handleCheckbox(label)" :key="idx">
        <input class="checkbox" type="checkbox" :value="label" v-model="selectedNamesArray" />
        <label>{{ label }} </label>
      </div>
    </div>
    <div class="selected-list" v-if="showLabelTags">
      <div v-for="(label, idx) in selectedNamesArray" :key="idx" :style="{
        animationDelay: idx / 10 + 's',
      }" class="label-tag">
        {{ label }}
        <i :style="{
          marginLeft: '5px',
          cursor: 'pointer',
          color: 'white',
        }" @click="handleClose(label)" class="bi bi-x"></i>
      </div>
    </div>
    <div class="view-button-container">
      <div :style="{
        fontSize: '14px',
        margin: '5px 10px 5px 10px',
        color: 'gray',
      }">
        View:
      </div>
      <button class="change-view-button" @click="view = 'spiral'" :style="{
        backgroundColor: view === 'spiral' ? 'black' : 'white',
        color: view === 'spiral' ? 'white' : 'black',
      }">
        Spiral
      </button>
      <button class="change-view-button" @click="view = 'gallery'" :style="{
        right: '490px',
        backgroundColor: view === 'gallery' ? 'black' : 'white',
        color: view === 'gallery' ? 'white' : 'black',
      }">
        Gallery
      </button>
    </div>
  </div>
  <div class="library-container" :style="{
    filter: isFocused ? 'blur(5px)' : 'none',
  }">
    <div v-if="isClicked && view === 'gallery'" class="action-container">
      <img v-if="isClicked" :src="isClicked.image_data" alt="" class="action-image" />
      <button @click="
        isClicked = null;
      view = 'gallery';
      " class="spiral-close-button">
        <i class="bi bi-x"></i>
      </button>
      <div v-if="isClicked" class="info-container" :style="{
        textAlign: view === 'gallery' ? 'center' : '',
        marginTop: view === 'gallery' ? '5px' : '',
      }">
        <div class="label-name">{{ selectedImageLabel }}</div>
        <div class="spiral-button-div">
          <span class="spiral-select-button" @click="selectImageForOtherSteps(isClicked.image_data)">Use</span>
        </div>
      </div>
    </div>

    <div class="image-spiral" :style="{
      display: view === 'spiral' ? 'block' : 'flex',
      flexWrap: view === 'sprial' ? 'none' : 'wrap',
      overflowY: view === 'gallery' ? 'scroll' : '',
      height:
        view === 'spiral'
          ? 'calc(100vh - 70px)'
          : filteredImageList.length > 32
            ? 'calc(100vh - 70px)'
            : 'auto',
    }">
      <div class="middle-title" v-if="view === 'spiral' && !isLoading" :style="{ left: isClicked ? '75%' : '50%' }">
        {{ filteredImageList.length }} Images
      </div>

      <img class="image" v-for="(image, idx) in filteredImageList" :key="idx" :src="image.image_data"
        @mouseenter="isHovered = idx" @mouseleave="isHovered = null" @click="
          isClicked = image;
        findLabelName(image.label_id);
        " :style="{
  transform:
    view === 'spiral'
      ? isClicked === image
        ? 'rotate(0)'
        : 'rotate(' +
        idx * (360 / filteredImageList.length) +
        'deg) translate(' +
        (filteredImageList.length > 50
          ? '30'
          : filteredImageList.length / 2) +
        '%, 50%)' +
        (isHovered === idx ? 'scale(1.2)' : '')
      : '',
  zIndex: view === 'spiral' ? (isHovered === idx ? '2' : '1') : '',
  filter: addImageIsClicked
    ? 'blur(10px)'
    : view === 'spiral'
      ? isHovered === idx || isHovered === null
        ? 'none'
        : isClicked === image
          ? 'none'
          : 'blur(5px)'
      : isClicked === image || isClicked === null
        ? ''
        : 'blur(10px)',
  left:
    view === 'spiral'
      ? isClicked === image
        ? '12.5%'
        : isClicked !== null
          ? '75%'
          : '50%'
      : '0',
  top:
    view === 'spiral'
      ? isClicked === image
        ? 'calc(50% - 150px)'
        : '50%'
      : '0',
  width:
    view === 'spiral'
      ? isClicked === image
        ? '300px'
        : '130px'
      : '12.5%',
  height:
    view === 'spiral'
      ? isClicked === image
        ? '300px'
        : '130px'
      : '130px',
  pointerEvents:
    isClicked === image || addImageIsClicked || isFocused
      ? 'none'
      : 'auto',
  position: view === 'spiral' ? 'absolute' : 'relative',
}" />
      <button class="close-button" v-if="isClicked && view === 'spiral'" :style="{
        top: 'calc(50% - 225px)',
        left: 'calc(12.5% + 135px)',
        backgroundColor: 'black',
        color: 'white',
      }" @click="isClicked = null">
        <i class="bi bi-x"></i>
      </button>
      <div v-if="isClicked && view === 'spiral'" :style="{
        position: 'absolute',
        bottom: '20%',
        left: '12.5%',
      }" class="info-container">
        <div class="label-name">{{ selectedImageLabel }}</div>
        <div v-if="isClicked && view === 'spiral'" class="spiral-button-div">
          <button class="use-pattern-button" @click="selectImageForOtherSteps(isClicked.image_data)">Use Pattern</button>
        </div>
      </div>
    </div>
    <button v-if="imageList.length !== 0" class="load-more-button" :style="{
      left: isClicked ? '75%' : '50%',
      display: view === 'spiral' ? 'block' : 'none',
      backgroundColor: shuffleLoading ? 'lightgray' : 'white',
      opacity: shuffleLoading ? '0.5' : '1',
      pointerEvents: shuffleLoading ? 'none' : 'auto',
      animation: shuffleLoading
        ? 'LoadingAnimation 1s ease infinite'
        : 'none',
    }" @click="shuffleImages()">
      {{ shuffleLoading ? "Waiting" : "Shuffle" }}
    </button>
  </div>
</template>

<script>
import { mapActions } from "pinia";
import { useTdplStore } from "@/store/modules/userTdpl";
import vClickOutside from "click-outside-vue3";
import * as mdb from "bootstrap";

export default {
  inheritAttrs: false,
  name: "UploadFileApp",
  props: ["data"],
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      imageList: this.data.library,
      labelList: this.data.labels,
      labelName: "",
      labelNameArray: [],
      selectedLabelIDArray: [],
      selectedLabels: [],
      isFocused: false,
      showLabelTags: false,
      view: "spiral",
      addImageIsClicked: false,
      showLabelsInAddImage: false,
      selectedLabelInAddImage: "",
      isHovered: null,
      isClicked: null,
      filteredImages: [],
      tdplModal: null,
      tdplStatus: true,
      langStatus: true,
      selectedNamesArray: [],
      selectedImageLabel: "",
      shuffleLoading: false,
    };
  },
  computed: {
    filteredImageList() {
      if (!this.showLabelTags) {
        return this.imageList;
      }
      return this.imageList.filter((image) =>
        this.selectedLabelIDArray.includes(image.label_id)
      );
    },
  },
  methods: {
    ...mapActions(useTdplStore, ["fetchUserTdplData", "updateUserTdplData"]),

    onClickOutside(event) {
      const element = event.target.className;
      if (element === "option-label" || element === "filter-input") {
        this.isFocused = true;
      } else {
        this.isFocused = false;
      }
    },
    async fetchUserTdpl() {
      this.isLoading(true);
      await this.fetchUserTdplData().then((response) => {
        this.isLoading(false);
        if (
          response.data.data.tdpl_result.length === 0 ||
          !response.data.data.tdpl_result[0].status
        ) {
          this.tdplStatus = false;
          this.tdplModal.show();
        } else {
          this.getLabels();
        }
      });
    },
    declineTdpl() {
      this.tdplModal.hide();
      this.$emit("decline-tdpl");
    },
    async acceptTdpl() {
      this.isLoading(true);
      await this.updateUserTdplData({ tdpl_status: true })
        .then((response) => {
          console.log(response);
          this.accessableTypes = [0, 1, 2, 3, 4, 5];
          this.tdplModal.hide();
          this.getLabels();
        })
        .catch(() => {
          this.isLoading(false);
        });
    },
    getLabels() {
      this.$emit("UploadGetLabels");
    },
    isLoading(_stat) {
      this.$emit("ChangeLoaderStatus", _stat);
    },
    handleFilter() {
      this.labelNameArray = [];
      for (let i = 0; i < this.labelList.length; i++) {
        const currentName = this.labelList[i].label_name;
        if (
          currentName.includes(this.labelName) &&
          !this.labelNameArray.includes(currentName)
        ) {
          this.labelNameArray.push(currentName);
        }
      }
    },
    handleFilterButton() {
      this.isFocused = false;
      if (this.labelName === "" && this.selectedNamesArray.length === 0) {
        this.showLabelTags = false;
      } else {
        this.showLabelTags = true;
        this.labelList.forEach((label) => {
          this.selectedNamesArray.forEach((name) => {
            if (name === label.label_name) {
              if (!this.selectedLabelIDArray.includes(label.label_id)) {
                this.selectedLabelIDArray.push(label.label_id);
              }
            }
          });
        });
      }
    },
    handleFocus(focus) {
      this.isFocused = focus;
      this.showLabelTags = false;
    },
    handleCheckbox(labelChecked) {
      if (this.selectedNamesArray.includes(labelChecked)) {
        this.selectedNamesArray = this.selectedNamesArray.filter(
          (label) => label != labelChecked
        );
      } else {
        this.selectedNamesArray.push(labelChecked);
      }
    },
    handleClose(removeLabelName) {
      this.selectedNamesArray = this.selectedNamesArray.filter(
        (item) => item !== removeLabelName
      );
      let removeId = this.labelList.filter(
        (label) => label.label_name === removeLabelName
      )[0].label_id;
      this.selectedLabelIDArray = this.selectedLabelIDArray.filter(
        (id) => id !== removeId
      );
      if (this.selectedNamesArray.length === 0) {
        this.showLabelTags = false;
      }
    },
    findLabelName(labelId) {
      this.selectedImageLabel = this.labelList.filter(
        (label) => label.label_id === labelId
      )[0].label_name;
    },
    selectImageForOtherSteps(_image_data) {
      this.$emit("SetImage", _image_data);
    },
    prepareLabels() {
      this.labelList.forEach((item) => {
        if (!this.labelNameArray.includes(item.label_name)) {
          this.labelNameArray.push(item.label_name);
        }
      });
    },
    shuffleImages() {
      this.shuffleLoading = true;
      setTimeout(() => {
        this.shuffle(this.imageList).then(() => {
          this.shuffleLoading = false;
        });
      }, 1000);
    },
    async shuffle(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = await Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    },
  },
  created() { },
  mounted() {
    this.isLoading(false);
    let tdplModal = document.querySelector("#tdplModal");
    this.tdplModal = new mdb.Modal(tdplModal);
    this.fetchUserTdpl().then(() => { });
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Fira+Mono:400");

.library-container {
  width: 100%;
  height: 100%;
  box-shadow: 0 0 10px 2px #ced4da;
  animation: SlideAnimation 0.8s ease;
  transition: all 0.5s;
  position: relative;
  padding: 0;
  overflow: hidden;
}

.filter-input {
  width: 250px !important;
  height: 40px !important;
  padding: 0 10px 0 10px !important;
  box-shadow: 0 0 10px 2px #ced4da;
  border: none !important;
  margin: 0 !important;
  position: absolute;
  top: 70px;
  right: 140px;
  font-size: 13px;
  outline: none;
  z-index: 2;
}

.filter-button {
  width: 100px;
  height: 40px;
  position: absolute;
  top: 70px;
  right: 40px;
  border: none;
  box-shadow: 0 0 10px 2px #ced4da;
  background-color: black;
  color: white;
  transition: 0.2s all;
  z-index: 2;
}

.filter-button:hover {
  font-size: 12px;
}

.datalist {
  width: 350px;
  height: auto;
  max-height: 400px;
  position: absolute;
  top: 120px;
  right: 40px;
  z-index: 3;
  background-color: white;
  overflow-y: scroll;
  box-shadow: 0 0 10px 2px #ced4da;
  transition: 0.5s all;
}

.option-label {
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 30px 0 30px;
  border-bottom: 1px solid #ced4da;
  cursor: pointer;
  font-size: 14px;
  transition: 0.2s all;
}

.option-label:hover {
  background-color: black;
  color: white;
}

.checkbox {
  cursor: pointer;
  margin-right: 20px;
}

label {
  cursor: pointer;
}

.selected-list {
  width: auto;
  height: 40px;
  position: absolute;
  top: 120px;
  right: 40px;
  display: flex;
  overflow: hidden;
  padding-left: 20px;
  transition: 1s all;
  z-index: 2;
}

.label-tag {
  width: auto;
  height: 40px;
  padding: 0 10px 0 10px;
  margin: 0 3px 0 3px;
  box-sizing: border-box;
  background-color: black;
  color: white;
  font-size: 14px;
  display: flex;
  align-items: center;
  opacity: 0;
  animation: LeftSlideAnimation 1s forwards;
}

.image-spiral {
  width: 100%;
  height: 100%;
  display: flex;
  transition: 0.5s all;
  position: relative;
}

.image {
  width: 100px;
  height: 100px;
  position: absolute;
  border: 1px solid lightgray;
  transform-origin: top left;
  left: 50%;
  top: 25%;
  transition: 0.5s all;
  cursor: pointer;
}

.middle-title {
  position: absolute;
  left: 50%;
  bottom: 30px;
  transition: all 0.5s;
  transform: translateX(-50%);
  font-size: 14px;
}

.view-button-container {
  width: auto;
  height: 40px;
  position: absolute;
  top: 70px;
  right: 400px;
  background-color: white;
  box-shadow: 0 0 10px 2px #ced4da;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.change-view-button {
  width: 80px;
  height: 30px;
  border: none;
  box-shadow: 0 0 10px 2px #ced4da;
  background-color: black;
  color: white;
  transition: 0.2s all;
  font-size: 14px;
  z-index: 3;
  margin: 5px;
}

.action-container {
  width: auto;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 0 0 10px 2px #ced4da;
  animation: OpacityAnimation 0.8s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  z-index: 5;
  padding: 50px;
}

.action-image {
  width: 300px;
  height: 300px;
}

.info-container {
  width: 300px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.delete-button {
  width: 100px;
  height: 40px;
  position: absolute;
  bottom: 10px;
  left: calc(12.5% + 100px);
  border: 0.5px solid lightgray;
  background-color: white;
  color: black;
  transition: 0.2s all;
  font-size: 14px;
}

.delete-button:hover {
  box-shadow: 0 0 10px 2px lightgray;
  transform: scale(1.05);
}

.close-button {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 10px;
  right: 10px;
  border: 0.5px solid lightgray;
  background-color: white;
  color: black;
  transition: 0.2s all;
  font-size: 14px;
}

.close-button:hover {
  box-shadow: 0 0 10px 2px lightgray;
  transform: scale(1.05);
}

.spiral-close-button {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 2%;
  z-index: 2;
  border: 0.5px solid lightgray;
  background-color: black;
  color: white;
  transition: 0.2s all;
  font-size: 14px;
}

.spiral-close-button:hover {
  box-shadow: 0 0 10px 2px lightgray;
  transform: scale(1.05);
}

.new-image-button {
  width: 150px;
  height: 40px;
  position: absolute;
  top: 70px;
  right: 645px;
  border: none;
  box-shadow: 0 0 10px 2px #ced4da;
  background-color: lightgray;
  color: black;
  transition: 0.2s all;
  font-size: 14px;
  transition: 0.2s all;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.new-image-button:hover {
  background-color: black;
  color: white;
}

.upload-button {
  width: 250px;
  height: 250px;
  border: 1px dashed black;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  transition: 0.2s all;
}

.upload-button:hover {
  background-color: black;
  border: 1px dashed white;
}

.load-more-button {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  border: none;
  background-color: white;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0;
  margin: 0;
  font-size: 14px;
  transition: 0.5s all;
  box-shadow: 0 0 10px 2px #ced4da;
  text-align: center;
}

.load-more-button:hover {
  box-shadow: 0 0 10px 5px #90959a;
}

@keyframes SlideAnimation {
  0% {
    transform: translateY(800px);
  }

  60% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes LeftSlideAnimation {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }

  60% {
    transform: translateX(-20px);
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes OpacityAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.select-button {
  font: 18px Arial;
  display: inline-block;
  padding: 0.125em 0.25em;
  text-align: center;
  color: white;
  background: linear-gradient(to left, transparent 50%, rgb(255, 255, 255) 50%) right;
  background-size: 200%;
  border-radius: 10px;
  transition: 0.5s ease-out;
}

.select-button:hover {
  cursor: pointer;
  color: black;
  background-position: left;
  transition: 0.5s ease-out;
}

.spiral-button-div {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
}

.spiral-select-button {
  font-family: 18px, "Fira Mono", monospace;
  letter-spacing: 1px;
  padding: 0.5em 0.5em;
  text-align: center;
  color: grey;
  background: linear-gradient(to left, transparent 51%, rgb(218, 218, 218) 50%) right;
  background-size: 200%;
  border-radius: 10px;
  transition: 0.5s ease-out;
}

.spiral-select-button:hover {
  cursor: pointer;
  color: black;
  background-position: left;
  transition: 0.5s ease-out;
}

.label-name {
  font-family: "Fira Mono", monospace;
  animation: glitch 1s linear forwards;
  animation-delay: 0.1s;
  letter-spacing: 5px;
}

@keyframes glitch {

  2%,
  84% {
    transform: translate(0, 0) skew(1deg);
  }

  4%,
  70% {
    transform: translate(0, 0) skew(1deg);
  }

  92% {
    transform: translate(0, 0) skew(200deg);
  }
}

.label-name:before,
.label-name:after {
  position: absolute;
  left: 0;
}

.use-pattern-button {
  width: 100px;
  height: 40px;
  border: none;
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.2s all;
  z-index: 3;
}

.use-pattern-button:hover {
  transform: scale(1.1);

}
</style>
