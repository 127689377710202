import axios from "axios";
import { defineStore } from "pinia";

export const useAvailableCreationStore = defineStore("availableCreation", {
  state: () => {
    return { availableCreationCount: null };
  },
  getters: {},
  actions: {
    async fetchAvailableCreations() {
      try {
        const url = "/admin/company_management/available-creations";

        const response = await axios.get(url);
        const availableCreationResponse =
          response.data.data.available_creations;
        if (typeof availableCreationResponse === "number") {
          this.availableCreationCount = availableCreationResponse;
        }
        return availableCreationResponse;
      } catch (error) {
        console.error("Error fetching available cretaions:", error);
        throw error;
      }
    },
  },
});
