<template>
  <div class="row p-5">
    <span
      v-show="hideAvailableCreations"
      :class="{ shake: limitationAnimation }"
      style="text-align: right"
      data-toggle="tooltip"
      title="Your company's remaining creation right."
    >
      <a :style="{ color: countText }"
        >Available Creations: <strong>{{ getAvailableCreations }}</strong></a
      >
    </span>
    <span
      style="text-align: right; margin-bottom: 20px"
      data-toggle="tooltip"
      title="Pattern Repeat Mode."
    >
      <div class="d-block">
        <label class="height-label"
          >Pattern Repeat Mode:
          <select
            id="PatternRepeatMode"
            v-model="this.PatternRepeatMode"
            :disabled="this.viewData.newAiApiRequest"
          >
            <option :value="this.viewData.repeatModeTypes.block">
              {{ this.viewData.repeatModeTypes.block }}
            </option>
            <option :value="this.viewData.repeatModeTypes.halfdrop">
              {{ this.viewData.repeatModeTypes.halfdrop }}
            </option>
            <option :value="this.viewData.repeatModeTypes.brick">
              {{ this.viewData.repeatModeTypes.brick }}
            </option>
            <option :value="this.viewData.repeatModeTypes.none">
              {{ this.viewData.repeatModeTypes.none }}
            </option>
            <option :value="this.viewData.repeatModeTypes.continuous_x">
              {{ this.viewData.repeatModeTypes.continuous_x }}
            </option>
            <option :value="this.viewData.repeatModeTypes.continuous_y">
              {{ this.viewData.repeatModeTypes.continuous_y }}
            </option>
          </select>
        </label>
      </div>
      <div>
        <button
          class="btn mt-4 btn-create-more"
          @click="btnLoadMoreDataClicked"
          :disabled="this.viewData.newAiApiRequest"
          v-if="
            this.viewData.svg_pattern_list.length == 0 &&
            this.viewData.svg_pattern_list_v2.length == 0
          "
        >
          Create
        </button>
      </div>
    </span>
    <!-- <span style="text-align: right;" data-toggle="tooltip" title="Your company's remaining download right.">
                <a>Available Downloads: <strong>{{ getDownloadRight }}</strong></a>
            </span> -->
    <div class="container p-0">
      <div class="row">
        <div class="col-6 leftDiv p-0">
          <div class="row">
            <div
              class="col-6 mb-4 col-element"
              v-for="item in this.viewData.svg_pattern_list"
              :key="item.image_filename"
            >
              <div class="position-relative text-white image-content h-100">
                <div
                  v-if="item.image_filename === 'empty'"
                  class="spinner-border text-black"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
                <div v-else>
                  <img class="w-100" :src="item.image_data" />
                  <i
                    :class="
                      item.liked
                        ? 'bi bi-heart-fill me-3 mt-3 img-absolute-icon-right-top fa-lg redHeart'
                        : 'bi bi-heart me-3 mt-3 img-absolute-icon-right-top fa-lg'
                    "
                    style="z-index: 999"
                    @click="patternActionLikeImage(item)"
                  >
                  </i>
                  <div class="img-overlay" @click="openModal(item)">
                    <div class="div-absolute-icon-center-center">
                      <div
                        class="d-flex justify-content-center align-items-center h-100"
                      >
                        <span
                          class="img-absolute-icon-center-center d-flex align-items-center justify-content-center"
                        >
                          <i class="fa fa-search fa-md"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 rightDiv p-0">
          <div class="row">
            <div
              class="col-6 mb-4 col-element"
              v-for="item in this.viewData.svg_pattern_list_v2"
              :key="item.image_filename"
            >
              <div class="position-relative text-white image-content h-100">
                <div
                  v-if="item.image_filename === 'empty'"
                  class="spinner-border text-black"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
                <div v-else>
                  <img class="w-100" :src="item.image_data" />
                  <i
                    :class="
                      item.liked
                        ? 'bi bi-heart-fill me-3 mt-3 img-absolute-icon-right-top fa-lg redHeart'
                        : 'bi bi-heart me-3 mt-3 img-absolute-icon-right-top fa-lg'
                    "
                    style="z-index: 999"
                    @click="patternActionLikeImageV2(item)"
                  >
                  </i>
                  <div class="img-overlay" @click="openModal(item)">
                    <div class="div-absolute-icon-center-center">
                      <div
                        class="d-flex justify-content-center align-items-center h-100"
                      >
                        <span
                          class="img-absolute-icon-center-center d-flex align-items-center justify-content-center"
                        >
                          <i class="fa fa-search fa-md"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="
        notifierDiv &&
        this.viewData.svg_pattern_list.length === 0 &&
        this.viewData.svg_pattern_list_v2.length === 0
      "
    >
      <span class="creation-warning"
        >You and the other users cannot create a new pattern because your
        company has <strong>reached the creation limit.</strong> You have not
        rights to create at the moment, please contact us.</span
      >
    </div>
    <div class="d-flex justify-content-center">
      <button
        class="btn mt-4 btn-create-more"
        @click="btnLoadMoreDataClicked"
        :disabled="this.viewData.newAiApiRequest"
        v-if="
          this.viewData.svg_pattern_list.length > 0 &&
          this.viewData.svg_pattern_list_v2.length > 0
        "
      >
        Create More
      </button>
    </div>
  </div>
  <div
    class="modal fade"
    id="mdlSelectedContent"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg bg-white">
      <div class="modal-content bg-white">
        <div class="modal-body">
          <div class="row">
            <div class="col-4">
              <button
                class="btn-modal-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                @click="closeDownloadButton"
              >
                X
              </button>
              <div class="d-flex justify-content-end mt-4">
                <!-- <button class="btn p-0 ps-3">
                                    <span class="text-decoration-underline mythIconMd" @click="resetValues()">Clear
                                        All</span>
                                </button> -->
              </div>
              <div v-if="this.imageRepeatTipi == 'block'">
                <div class="d-flex justify-content-between mt-3">
                  <span>Scale</span>
                </div>
                <div class="sliderScaleDiv">
                  <input
                    type="range"
                    min="32"
                    max="1024"
                    step="10"
                    v-model="sizeVall"
                    class="form-range w-100"
                    id="sliderScale"
                  />
                </div>
              </div>

              <div
                v-if="
                  this.viewData.selectedDownloadType !=
                  this.viewData.downloadSrcTypes.svg
                "
              >
                <div class="d-flex pt-3 resizeDiv">
                  <div class="d-block">
                    <label
                      >Width:
                      <input
                        class="w-100"
                        type="number"
                        name="widthInput"
                        v-model="this.viewData.downloadWidth"
                        min="256"
                        max="8190"
                      />
                    </label>
                  </div>
                  <div class="d-block ms-1">
                    <label class="height-label"
                      >Height:
                      <input
                        class="w-100"
                        type="number"
                        name="heightInput"
                        v-model="this.viewData.downloadHeight"
                        min="256"
                        max="8190"
                      />
                    </label>
                  </div>
                  <div class="d-block ms-1">
                    <label class="height-label"
                      >Size Type:
                      <select
                        id="downloadSizetype"
                        v-model="this.viewData.selectedDownloadSizeType"
                      >
                        <option :value="this.viewData.downloadSizeTypes.px">
                          {{ this.viewData.downloadSizeTypes.px }}
                        </option>
                        <option :value="this.viewData.downloadSizeTypes.cm">
                          {{ this.viewData.downloadSizeTypes.cm }}
                        </option>
                        <option :value="this.viewData.downloadSizeTypes.inch">
                          {{ this.viewData.downloadSizeTypes.inch }}
                        </option>
                      </select>
                    </label>
                  </div>
                </div>
                <div class="d-flex pt-3 resizeDiv">
                  <div class="d-block">
                    <label
                      >DPI:
                      <input
                        class="w-100"
                        type="number"
                        name="dpiInput"
                        v-model="this.viewData.dpiInput"
                        min="100"
                        max="500"
                      />
                    </label>
                  </div>
                  <div class="d-block ms-1">
                    <label
                      >Export Type:
                      <select id="downloadType" @change="downloadTypeChanged">
                        <option :value="this.viewData.downloadSrcTypes.tiff">
                          {{ this.viewData.downloadSrcTypes.tiff }}
                        </option>
                        <option :value="this.viewData.downloadSrcTypes.svg">
                          {{ this.viewData.downloadSrcTypes.svg }}
                        </option>
                        <option :value="this.viewData.downloadSrcTypes.jpeg">
                          {{ this.viewData.downloadSrcTypes.jpeg }}
                        </option>
                        <option :value="this.viewData.downloadSrcTypes.png">
                          {{ this.viewData.downloadSrcTypes.png }}
                        </option>
                        <option :value="this.viewData.downloadSrcTypes.pdf">
                          {{ this.viewData.downloadSrcTypes.pdf }}
                        </option>
                      </select>
                    </label>
                  </div>
                </div>
              </div>
              <div
                v-if="
                  this.viewData.selectedDownloadType ==
                  this.viewData.downloadSrcTypes.svg
                "
              >
                <div class="d-flex pt-3 resizeDiv" style="min-height: 76px">
                  <div class="d-block"></div>
                  <div class="d-block ms-1"></div>
                  <div class="d-block ms-1"></div>
                </div>
                <div class="d-flex pt-3 resizeDiv">
                  <div class="d-block">
                    <label
                      >Color Count:
                      <input
                        class="w-100"
                        type="number"
                        name="ColorCountInput"
                        v-model="this.viewData.colorCount"
                        min="1"
                      />
                    </label>
                  </div>
                  <div class="d-block ms-1" style="min-width: 120px">
                    <label
                      >Export Type:
                      <select id="downloadType" @change="downloadTypeChanged">
                        <option :value="this.viewData.downloadSrcTypes.svg">
                          {{ this.viewData.downloadSrcTypes.svg }}
                        </option>
                        <option :value="this.viewData.downloadSrcTypes.tiff">
                          {{ this.viewData.downloadSrcTypes.tiff }}
                        </option>
                        <option :value="this.viewData.downloadSrcTypes.jpeg">
                          {{ this.viewData.downloadSrcTypes.jpeg }}
                        </option>
                        <option :value="this.viewData.downloadSrcTypes.png">
                          {{ this.viewData.downloadSrcTypes.png }}
                        </option>
                        <option :value="this.viewData.downloadSrcTypes.pdf">
                          {{ this.viewData.downloadSrcTypes.pdf }}
                        </option>
                      </select>
                    </label>
                  </div>
                </div>
              </div>
              <div class="mt-5" style="text-align: right">
                <button
                  class="w-100 btn border mt-2 btn-dark"
                  @click="certModal.show()"
                >
                  Download Image
                </button>

                <button
                  class="w-100 btn border mt-2 btn-dark"
                  @click="btnPatternUseClicked"
                  :disabled="this.viewData.newAiApiRequest"
                >
                  Use Pattern
                </button>
              </div>
              <div v-if="imageTypeAlertMessage">
                <p style="color: red">Please select the image type</p>
              </div>
            </div>
            <div class="col-8">
              <div
                :id="'img-canvasdiv'"
                :style="`background-image: url(${this.imageForShow}); background-repeat: repeat; background-size: ${sizeVall}px; width: 512px; height: 512px;`"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="certificateNameModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="certificateNameModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="certificateNameModalLabel">
            Certificate Name
          </h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <div class="form-group">
              <label for="certificate-name" class="col-form-label"
                >Certificate Name:</label
              >
              <input
                type="text"
                class="form-control"
                id="certificate-name"
                v-model="certificateName"
              />
            </div>
            <span class="form-error-message" v-if="handleErrorMessages">{{
              certificateNameErrorMessage
            }}</span>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="btnDownloadImageClicked"
          >
            Generate with this name
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jsPDF from "jspdf";
import * as mdb from "bootstrap";
import { mapActions } from "pinia";
import { useDownloadRightStore } from "@/store/modules/downloadRight";
import { useAvailableCreationStore } from "@/store/modules/availableCreation";
import { useUserCompanyStore } from "@/store/modules/userCompany";
import { useUserCertificateStore } from "@/store/modules/userCertificate";
import { montserratBold } from "@/assets/fonts/montserratBold";
import { montserratRegular } from "@/assets/fonts/montserratRegular";
import { montserratBoldItalic } from "@/assets/fonts/montserratBoldItalic";
import { carattereRegular } from "@/assets/fonts/carattereRegular";

export default {
  inheritAttrs: false,
  name: "PatternApp",
  props: ["fileSrc", "data"],
  data() {
    return {
      viewData: this.data,
      modal: null,
      certModal: null,
      certificateName: "",
      sizeVall: 512,
      imageForShow: null,
      imageRepeatTipi: null,
      imageTypeAlertMessage: false,
      companyName: "",
      hideAvailableCreations: true,
      notifierDiv: false,
      limitationAnimation: false,
      countText: "black",
      certificateNameErrorMessage: "",
      PatternRepeatMode: "Block Repeat",
      RepeatModeTypeslist: {
        "Block Repeat": "block",
        "Half-drop Repeat": "half_drop",
        "Brick Repeat": "brick",
        "Placement Print": "none",
        "Strip Horizontal Repeat": "continuous_x",
        "Strip Vertical Repeat": "continuous_y",
      },
    };
  },
  computed: {
    getDownloadRight() {
      const downloadRightStore = useDownloadRightStore();
      return downloadRightStore.downloadRight;
    },
    getAvailableCreations() {
      const availableCreationStore = useAvailableCreationStore();
      return availableCreationStore.availableCreationCount;
    },
  },
  methods: {
    ...mapActions(useDownloadRightStore, ["fetchDownloadRight"]),
    ...mapActions(useAvailableCreationStore, ["fetchAvailableCreations"]),
    ...mapActions(useUserCompanyStore, ["fetchUserCompanyData"]),
    ...mapActions(useUserCertificateStore, ["insertCertificateRequest"]),

    scrollUp() {
      document.querySelector("#container").scrollTo(0, 0);
      this.limitationAnimation = true;
      this.countText = "red";
      setTimeout(() => {
        this.countText = "black";
      }, 800);
      setTimeout(() => {
        this.limitationAnimation = false;
      }, 2000);
    },
    btnPatternUseClicked() {
      this.modal.hide();
      this.$emit("PatternUsePhotoClicked", this.viewData.selectedImage);
    },
    btnLoadMoreDataClicked() {
      for (const [key, value] of Object.entries(this.RepeatModeTypeslist)) {
        if (key == this.PatternRepeatMode) {
          var RepeatModes = value;
        }
      }
      this.$emit("PatternLoadData", RepeatModes);
    },
    patternActionLikeImage(_image) {
      const foundImage = this.viewData.svg_pattern_list.find(
        (pattern) => pattern.image_filename === _image.image_filename
      );

      if (_image.liked) {
        this.deleteItem(_image.image_filename);
        foundImage.liked = false;
        foundImage.buttonStatus = false;
      } else {
        foundImage.liked = true;
        foundImage.buttonStatus = true;
        this.$emit("PaternActionLikeImage", _image);
      }
    },
    patternActionLikeImageV2(_image) {
      const foundImage2 = this.viewData.svg_pattern_list_v2.find(
        (pattern) => pattern.image_filename === _image.image_filename
      );
      if (_image.liked) {
        this.deleteItem(_image.image_filename);
        foundImage2.liked = false;
        foundImage2.buttonStatus = false;
      } else {
        foundImage2.liked = true;
        foundImage2.buttonStatus = true;
        this.$emit("PaternActionLikeImage", _image);
      }
    },
    deleteItem(deleteItemName) {
      this.$emit("unLikeItem2", deleteItemName);
    },
    openModal(_image) {
      this.imageTypeAlertMessage = false;
      this.viewData.selectedImage = _image;
      this.imageForShow = _image.image_data;
      this.imageRepeatTipi = _image.repeatmodeofImage;
      this.sizeVall = 512;
      this.modal.show();
    },
    setElements() {
      let elements = document.querySelectorAll(".col-element");
      elements.forEach((f) => {
        f.addEventListener("click", () => {});
      });
    },
    downloadSizeTypeChanged() {
      this.viewData.selectedDownloadSizeType =
        document.querySelector("#downloadSizetype").value;
    },
    downloadTypeChanged() {
      this.viewData.selectedDownloadType =
        document.querySelector("#downloadType").value;
    },
    resetValues() {
      this.sizeVall = 512;
      this.viewData.downloadHeight = 1024;
      this.viewData.downloadWidth = 1024;
      this.viewData.dpiInput = 300;
    },
    btnDownloadImageClicked() {
      if (this.certificateName.trim().length === 0) {
        this.certificateNameErrorMessage = "Certificate name cannot be empty!";
      } else if (this.certificateName.trim().length < 3) {
        this.certificateNameErrorMessage =
          "The certificate name must consist of at least 4 characters!";
      } else {
        if (this.companyName === "") {
          this.fetchUserCompanyData().then((response) => {
            console.log(response.data[0].company_name);
            this.companyName = response.data[0].company_name;
          });
        }
        this.viewData.repeat_count = Math.round(512 / this.sizeVall).toString();

        var width, height, downloadConfirmed;
        if (this.viewData.selectedDownloadSizeType == "cm") {
          width = (this.viewData.dpiInput * this.viewData.downloadWidth) / 2.54;
          height =
            (this.viewData.dpiInput * this.viewData.downloadHeight) / 2.54;
        } else if (this.viewData.selectedDownloadSizeType == "inch") {
          width = this.viewData.dpiInput * this.viewData.downloadWidth;
          height = this.viewData.dpiInput * this.viewData.downloadHeight;
        } else {
          width = this.viewData.downloadWidth;
          height = this.viewData.downloadHeight;
        }
        if (width > 8192 || height > 8192) {
          if (this.viewData.selectedDownloadSizeType == "cm") {
            const maxwidth = (8192 * 2.54) / this.viewData.dpiInput;
            const maxheight = (8192 * 2.54) / this.viewData.dpiInput;
            downloadConfirmed = confirm(`The wanted size (${
              this.viewData.downloadWidth
            }${this.viewData.selectedDownloadSizeType} * ${
              this.viewData.downloadHeight
            }${this.viewData.selectedDownloadSizeType}) is too large. 
                    \nThe maximum size allowed with DPI (${
                      this.viewData.dpiInput
                    }) is (${Math.round(maxwidth)}${
              this.viewData.selectedDownloadSizeType
            } * ${Math.round(maxheight)}${
              this.viewData.selectedDownloadSizeType
            }).
                    \nDo you want to download with the maximum allowed size?`);
          } else if (this.viewData.selectedDownloadSizeType == "inch") {
            const maxwidth = 8192 / this.viewData.dpiInput;
            const maxheight = 8192 / this.viewData.dpiInput;
            downloadConfirmed = confirm(`The wanted size (${
              this.viewData.downloadWidth
            }${this.viewData.selectedDownloadSizeType} * ${
              this.viewData.downloadHeight
            }${this.viewData.selectedDownloadSizeType}) is too large. 
                    \nThe maximum size allowed with DPI (${
                      this.viewData.dpiInput
                    }) is (${Math.round(maxwidth)}${
              this.viewData.selectedDownloadSizeType
            } * ${Math.round(maxheight)}${
              this.viewData.selectedDownloadSizeType
            }).
                    \nDo you want to download with the maximum allowed size?`);
          } else {
            downloadConfirmed =
              confirm(`The wanted size (${this.viewData.downloadWidth}${this.viewData.selectedDownloadSizeType} * ${this.viewData.downloadHeight}${this.viewData.selectedDownloadSizeType}) is too large. 
                     \nThe maximum size allowed is (8192${this.viewData.selectedDownloadSizeType} * 8192${this.viewData.selectedDownloadSizeType}).
                     \nDo you want to download with the maximum allowed size?`);
          }
          if (downloadConfirmed) {
            const updatedViewData = { ...this.viewData };
            width = 8192;
            height = 8192;
            updatedViewData.dpiInput = 300;
            updatedViewData.selectedDownloadSizeType =
              this.viewData.downloadSizeTypes.px;
            updatedViewData.downloadWidth = Math.round(width);
            updatedViewData.downloadHeight = Math.round(height);
            this.$emit("PatternDownloadImage", this.viewData, updatedViewData);
            this.certModal.hide();
            this.generatePdf();
          }
        } else {
          const updatedViewData = { ...this.viewData };
          if (updatedViewData.dpiInput > 360) {
            updatedViewData.dpiInput = 360;
          }
          updatedViewData.selectedDownloadSizeType =
            this.viewData.downloadSizeTypes.px;
          updatedViewData.downloadWidth = Math.round(width);
          updatedViewData.downloadHeight = Math.round(height);
          this.$emit("PatternDownloadImage", this.viewData, updatedViewData);
          this.certModal.hide();
          this.generatePdf();
        }
      }
    },
    generatePdf() {
      this.createPdfContent().then((pdf) => {
        var blobPdf = pdf.output("blob");
        var reader = new FileReader();
        reader.readAsDataURL(blobPdf);
        reader.onloadend = () => {
          var base64data = reader.result.split(",")[1];
          this.insertCertificateRequest({
            certificate_name: this.certificateName.trim(),
            certificate_data: base64data,
          }).then(() => {});
        };
      });
    },
    createPdfContent() {
      return new Promise((resolve) => {
        let selectedImage = this.viewData.selectedImage;
        // let pdfName = selectedImage.image_filename.slice(0, -3)
        var pdf = new jsPDF("l", "pt", "", true);

        pdf.addFileToVFS(
          "@/assets/fonts/Montserrat-Regular.tff",
          montserratRegular
        );
        pdf.addFileToVFS("@/assets/fonts/Montserrat-Bold.tff", montserratBold);
        pdf.addFileToVFS(
          "@/assets/fonts/Montserrat-BoldItalic.tff",
          montserratBoldItalic
        );
        pdf.addFileToVFS(
          "@/assets/fonts/Carattere-Regular.tff",
          carattereRegular
        );
        pdf.addFont(
          "@/assets/fonts/Montserrat-Regular.tff",
          "montserratR",
          "normal"
        );
        pdf.addFont(
          "@/assets/fonts/Montserrat-Bold.tff",
          "montserratB",
          "normal"
        );
        pdf.addFont(
          "@/assets/fonts/Montserrat-BoldItalic.tff",
          "montserratBI",
          "normal"
        );
        pdf.addFont(
          "@/assets/fonts/Carattere-Regular.tff",
          "carattereR",
          "normal"
        );

        var width = pdf.internal.pageSize.getWidth();
        var height = pdf.internal.pageSize.getHeight();

        let date = new Date().toLocaleDateString("en-TR");
        let time = new Date().toLocaleTimeString("en-TR");

        var mythLogo = new Image();
        var backGroundImage = new Image();
        mythLogo.src = require("@/assets/img/logo.png");
        backGroundImage.src = require("@/assets/img/pdf-background.png");
        backGroundImage.onload = () => {
          pdf.addImage(
            backGroundImage,
            "png",
            0,
            0,
            width,
            height,
            undefined,
            "FAST"
          );
          pdf.addImage(
            mythLogo,
            "png",
            width / 2 - 25,
            75,
            50,
            50,
            undefined,
            "FAST"
          );

          pdf.setFont("montserratR", "normal");
          pdf.setTextColor(0, 0, 0);
          pdf.setFontSize(24);
          pdf.text("CERTIFICATE OF OWNERSHIP", width / 2, 175, {
            align: "center",
          });

          pdf.setFont("montserratR", "normal");
          pdf.setFontSize(12);
          pdf.text("THIS CERTIFICATE BELONGS TO:", width / 2, 215, {
            align: "center",
          });

          pdf.setFont("carattereR", "normal");
          pdf.setTextColor(254, 99, 99);
          pdf.setFontSize(28);
          pdf.text(this.companyName, width / 2, 245, { align: "center" });

          pdf.addImage(
            selectedImage.image_data,
            "png",
            width / 2 - 50,
            270,
            100,
            100,
            undefined,
            "FAST"
          );

          pdf.setFont("montserratR", "normal");
          pdf.setTextColor(0, 0, 0);
          pdf.setFontSize(11);
          pdf.text(
            `This certificate of ownership hereby assigns all intellectual property rights related to the pattern mentioned above to the Pattern Owner indefinitely and without limitations, as of ${date}-${time}.`,
            width / 2,
            400,
            { maxWidth: width * 0.6, lineHeightFactor: 1.5, align: "center" }
          );

          pdf.setFont("montserratR", "normal");
          pdf.setTextColor(254, 99, 99);
          pdf.setFontSize(10);
          pdf.text(
            "On behalf of MYTH Yapay Zekâ ve Tekstil Anonim Şirketi",
            width / 2,
            475,
            { align: "center" }
          );
          pdf.setTextColor(0, 0, 0);
          pdf.text("Özgecan Üstgül", width / 2, 490, { align: "center" });
          resolve(pdf);
        };
      });
    },
    handleErrorMessages() {
      if (this.certificateNameErrorMessage.length !== 0) {
        return true;
      }
    },
  },
  created() {
    // if (this.viewData.svg_pattern_list.length === 0) {
    //     this.$emit('PatternLoadData', this.PatternRepeatMode);
    // }
  },
  mounted() {
    this.leftItems = this.viewData.svg_pattern_list;
    this.rightItems = this.viewData.svg_pattern_list_v2;

    let modal = document.querySelector("#mdlSelectedContent");
    let certModal = document.querySelector("#certificateNameModal");

    this.modal = new mdb.Modal(modal);
    this.certModal = new mdb.Modal(certModal);

    this.fetchAvailableCreations()
      .then((res) => {
        if (typeof res === "string") {
          this.hideAvailableCreations = false;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  watch: {
    certificateName() {
      this.certificateNameErrorMessage = "";
    },
    viewData() {
      this.setElements();
    },
  },
};
</script>

<style>
.redHeart {
  color: red;
}

.btn-create-more {
  border: 1px solid gray;
}

.btn-create-more:hover {
  border: 2px solid black;
}

.modal-dialog {
  border-radius: 10px;
}

.resizeDiv input {
  height: 36px;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
}

.btn-use-pattern {
  color: white;
  background-color: #c2c2c2;
}

.btn-use-pattern:hover {
  color: black;
  background-color: gray;
}

.select-area {
  height: 36px;
  margin-right: 0px;
}

#downloadSizetype {
  height: 36px;
}

#downloadType {
  height: 36px;
}

.certificate-icon:hover {
  width: 66px;
  height: 66px;
}

.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
    font-size: 20px;
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.image-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.col-element {
  display: block;
  height: auto;
  max-width: 100%;
}
</style>
