import axios from "axios";
import { defineStore } from "pinia";

export const useTdplStore = defineStore("userTdpl", {
  state: () => {
    return { };
  },
  getters: {},
  actions: {
    async fetchUserTdplData() {
      try {
        const url = "/user/get-tdpl";

        const response = await axios.get(url);
        return response;
      } catch (error) {
        console.error("Error fetching tdpl data:", error);
        throw error;
      }
    },
    async updateUserTdplData(payload) {
      try {
        const url = "/user/update-tdpl";

        const response = await axios.patch(url, payload);
        return response;
      } catch (error) {
        console.error("Error updating tdpl data:", error);
        throw error;
      }
    },
  },
});
