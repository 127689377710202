<template>
  <div class="style-transfer-container">
    <button class="back-button" v-if="this.viewData.step !== 0" @click="this.btnBackClicked">
      <i class="bi bi-chevron-left"></i>Back
    </button>
    <button v-show="this.viewData.styleImage !== ''" v-if="this.viewData.step !== 1" class="apply-button"
      @click="this.btnApplyClicked">
      Apply
    </button>
    <button v-if="this.viewData.step === 1" class="apply-button" @click="this.btnContinueClicked">
      Apply
    </button>
    <div>
      <div>
        <div class="middle-container" v-if="this.viewData.step === 0">
          <div class="image-container" :style="{ left: '0' }">
            <div :style="{ position: 'absolute', top: '-30px' }">Input</div>
            <div class="left-image-frame">
              <img :style="{
                maxWidth: 'calc(100% - 20px)',
                maxHeight: '330px',
                margin: '10px',
              }" :src="inputData" />
            </div>
          </div>
          <button class="swap-button" @mouseenter="isHovered = true" @mouseleave="isHovered = false"
            @click="swapInputAndStyleImage()" v-if="this.viewData.secondImageUploaded"
            :style="{ width: isHovered ? '100px' : '40px' }">
            <i class="bi bi-arrow-right" id="right-arrow"></i>
            <i class="bi bi-arrow-left" id="left-arrow"></i>
            <div v-if="isHovered">Swap</div>
          </button>
          <div class="image-container" :style="{ right: '0' }">
            <div :style="{ position: 'absolute', top: '-30px' }">Style</div>
            <div class="dropzone-wrapper-st w-100">
              <div class="image-drag-drop-container w-100" :style="{ margin: 0 }">
                <div class="before-upload">
                  <input id="drag-drop-input" type="file" accept=".png,.jpg,.tiff,.jpeg,.svg" style="z-index: 1"
                    @change="this.ImgStyleChanged" />
                  <img v-if="this.viewData.styleImage === ''" style="width: 50px" id="img-uploaded-style"
                    src="../../assets/img/imageUpload.svg" />
                  <img v-if="this.viewData.styleImage !== ''" style="
                      max-height: 350px;
                      object-fit: cover;
                      min-width: 100%;
                    " id="img-uploaded-style" :src="this.viewData.styleImage" />
                  <div class="dropzone-title" type="button"></div>
                </div>
                <span v-if="this.viewData.styleImage === ''" style="font-size: 10px">25 MB Max Allowed</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row col-lg-12 w-100" :style="{ marginTop: '100px' }" v-if="this.viewData.step === 1">
          <div class="col-lg-6 col-md-12 mb-3 mb-lg-0 p-0 pe-lg-3">
            <div class="card h-100 border p-3">
              <!-- Input Features -->
              <div class="d-flex justify-content-between mt-3">
                <span>Input Features</span>
                <span id="">{{ this.viewData.content_blur_feature }}</span>
              </div>
              <div class="pb-5">
                <input type="range" min="0" max="100" v-model="this.viewData.content_blur_feature" class="form-range"
                  id="sliderOpacity" />
              </div>
              <!-- Input Features -->

              <!-- Smooth Detail -->
              <div class="d-flex justify-content-between mt-3">
                <span>Smooth Detail</span>
                <span id=""> {{ this.viewData.content_shape_feature }}</span>
              </div>
              <div class="sliderOpacityDiv pb-5">
                <input type="range" min="0" max="100" v-model="this.viewData.content_shape_feature" class="form-range"
                  id="sliderOpacity" />
              </div>
              <!-- Smooth Detail -->

              <!-- Style Features -->
              <div class="d-flex justify-content-between mt-3">
                <span>Style Features</span>
                <span id="">{{ this.viewData.style_shape_feature }}</span>
              </div>
              <div class="sliderOpacityDiv pb-5">
                <input type="range" min="1" max="100" v-model="this.viewData.style_shape_feature" class="form-range"
                  id="sliderOpacity" />
              </div>
              <!-- Style Features -->

              <!-- Style Colors -->
              <div class="d-flex justify-content-between mt-3">
                <span>Style Colors</span>
                <span id="">{{ this.viewData.style_color_feature }}</span>
              </div>
              <div class="sliderOpacityDiv pb-5">
                <input type="range" min="1" max="100" v-model="this.viewData.style_color_feature" class="form-range"
                  id="sliderOpacity" />
              </div>
              <!-- Style Colors -->
              <button v-if="this.viewData.styleImage !== ''" @click="this.btnApplyClicked"
                class="btn btn-outline-dark px-5 apply-settings-btn">
                Apply Settings
              </button>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 p-0">
            <div class="d-flex justify-content-center">
              <div class="position-relative text-white">
                <img v-if="this.viewData.styledImage !== ''" :src="this.viewData.styledImage" class="img-color"
                  style="max-height: 450px; object-fit: cover; width: 100%" />
                <div class="img-overlay">
                  <div class="div-absolute-icon-center-center">
                    <div class="d-flex justify-content-center align-items-center h-100">
                      <span class="img-absolute-icon-center-center-st d-flex align-items-center justify-content-center"
                        @click="downloadStyledImageTrigger('png')">
                        PNG<i class="fa fa-download fa-md"></i>
                      </span>
                      <div style="width: 20px"></div>
                      <span class="img-absolute-icon-center-center-st d-flex align-items-center justify-content-center"
                        @click="downloadStyledImageTrigger('tiff')">
                        TIFF<i class="fa fa-download fa-md"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <img v-if="this.viewData.styledImage === ''" :src="this.fileSrc" class="img-color"
                style="max-height: 450px; object-fit: cover" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "pinia";
import { useStyleTransferStore } from "@/store/modules/styleTransfer";
import { useUploadedImageStore } from "@/store/modules/uploadedImage";
import FileSaver from "file-saver";

export default {
  name: "StyleTransferApp",
  props: ["fileSrc", "data", "secondImageUploaded"],
  data() {
    return {
      viewData: this.data,
      inputData: this.fileSrc,
      isHovered: false,
    };
  },
  computed: {},
  methods: {
    ...mapActions(useStyleTransferStore, ["downloadStyledImage"]),

    isLoading(_stat) {
      this.$emit("ChangeLoaderStatus", _stat);
    },
    btnContinueClicked() {
      this.$emit("btnContinueClicked");
    },
    btnBackClicked() {
      this.viewData.step = 0;
      const uploadedImageStore = useUploadedImageStore();
      if (uploadedImageStore.checkColorStep) {
        this.$emit("setFirstImage");
        this.inputData = uploadedImageStore.uploadedImage;
        this.viewData.styleImage = "";
        this.viewData.secondImageUploaded = false;
      }
    },
    btnApplyClicked() {
      this.$emit("btnApplyClicked", this.viewData);
    },
    async handleImageFile(file) {
      return new Promise((resolve, reject) => {
        let input = file.target;
        let reader = new FileReader();
        if (Math.round(file.target.files[0].size / 1000000) > 25) {
          this.OpenNotify("Max size is 25 MB", false);
          return false;
        }
        reader.readAsDataURL(input.files[0]);
        reader.onload = async () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
      });
    },
    async ImgStyleChanged(file) {
      await this.handleImageFile(file).then((data) => {
        this.uploadedImage = data;
        this.viewData.styleImage = data;
      });
    },
    async downloadStyledImageTrigger(downloadType) {
      this.isLoading(true);
      await this.downloadStyledImage({
        download_type: downloadType,
        repeat_mode: "none",
      }).then((response) => {
        var blob = new Blob([response.data]);
        if (downloadType === "png") {
          FileSaver.saveAs(blob, "styled-image.png");
          this.isLoading(false);
        } else {
          FileSaver.saveAs(blob, "styled-image.tiff");
          this.isLoading(false);
        }
      });
    },
    swapInputAndStyleImage() {
      this.$emit("swapImages");
    },
  },
  created() {
    this.isLoading(true);
  },
  mounted() {
    this.isLoading(false);
  },
};
</script>

<style scoped>
.style-transfer-container {
  width: calc(100vw - 350px);
  height: calc(100vh - 70px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropzone-wrapper-st {
  height: 350px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.image-drag-drop-container {
  height: 350px;
  max-width: 100%;
}

.apply-settings-btn {
  margin: auto;
  width: 200px;
}

.apply-button {
  width: 150px;
  height: 40px;
  border: none;
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 100px;
  transition: 0.2s all;
  z-index: 3;
}

.back-button {
  width: 75px;
  height: 40px;
  border: none;
  background-color: transparent;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: absolute;
  left: calc(10% + 75px);
  top: 100px;
  transform: translateX(-50%);
  transition: 0.2s all;
  z-index: 20;
}

.back-button:hover {
  background-color: #000;
  color: #fff;
}

.apply-button:hover {
  transform: scale(1.1);
}

.middle-container {
  width: calc(90vw - 350px);
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.swap-button {
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
  background-color: transparent;
  position: relative;
  transition: 0.2s all;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border: none;
  cursor: pointer;
}

#left-arrow {
  position: absolute;
  top: 3px;
  left: 0px;
  font-size: 20px;
}

#right-arrow {
  position: absolute;
  bottom: 3px;
  right: 0px;
  font-size: 20px;
}

.image-container {
  width: calc(40vw - 175px);
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border: 1px dashed black;
}

/* .left-image-frame {
  min-width: 350px;
  width: 100%;
  height: 350px;
  object-fit: contain;
  border: 1px dashed gray;
  display: flex;
  justify-content: center;
  align-items: center;
} */
</style>
