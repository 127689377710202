<template>
  <div v-if="normalle" class="container w-100 p-5 container-vh-92">
    <div class="row">
      <div class="col-xl-6 col-md-12 mb-3 mb-lg-0 p-0 pe-lg-3">
        <div class="d-flex justify-content-center">
          <h1>Select Color Count for SVG</h1>
        </div>
        <div v-if="spinnerStatus" class="d-flex justify-content-center mt-5">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div
          v-if="!spinnerStatus"
          id="palettes"
          class="palettes"
          style="height: 308px; overflow-y: scroll"
        >
          <div
            id="palette"
            v-for="(group, index) in visibleGroups"
            :key="index"
            class="palette"
            :style="{
              'border-color':
                index === selectedPaletteIndex ? '#28a745' : '#fff',
            }"
          >
            <div
              class="d-flex flex-row align-items-center justify-content-center"
            >
              <input
                class="pallete-radio"
                :title="group.length"
                name="colors"
                type="radio"
                :value="index"
                @input="selectCountnumber(group.length)"
                @change="handleCheckboxChange"
              />
              <span class="num">{{ group.length }}:</span>
              <span
                v-for="(color, colorIndex) in group"
                :key="colorIndex"
                class="c"
                :style="{ 'background-color': `rgb(${color.join(',')})` }"
              ></span>
            </div>
          </div>
        </div>
        <div v-if="!spinnerStatus" class="d-flex justify-content-center mb-3">
          <button class="btn btn-outline-dark border" @click="showMore">
            Show More
          </button>
        </div>
        <div class="d-flex justify-content-center mb-3">
          <button
            v-if="selectedCount"
            class="btn btn-dark text-white"
            @click="this.btnVectorizerlicked"
          >
            Vectorize Pattern
          </button>
        </div>
      </div>
      <div class="col-xl-6 col-md-12 p-0 mt-3 mt-xl-0">
        <div class="d-flex justify-content-center pngBG position-relative">
          <img
            v-if="this.fileSrc != null"
            :src="this.fileSrc"
            class="p-4 img-color w-75"
          />
        </div>
      </div>
    </div>
  </div>
  <div v-if="!normalle" class="container w-100 p-5 container-vh-92">
    <div class="d-flex justify-content-between mb-3">
      <div class="d-flex justify-content-between flex-row w-100">
        <button
          class="btn btn-outline-dark border px-5"
          @click="this.btnBackClicked"
        >
          Back
        </button>
      </div>
      <div class="d-flex justify-content-between flex-row">
        <button
          v-show="!playgroundStatus"
          class="btn bg-dark text-white border px-3 me-3 version-button"
          @click="this.DownlaodSVGvectorized"
        >
          Download SVG
        </button>
        <button
          class="btn bg-dark text-white border px-3 version-button"
          @click="changePlaygroundStatus"
        >
          {{ tabName }}
        </button>
        <button
          v-if="appplyButtonControllerVect"
          v-show="!playgroundStatus"
          class="btn bg-dark text-white border px-5 version-button"
          @click="this.btnVectorizerApplyClicked"
        >
          Apply
        </button>
      </div>
    </div>
    <div v-show="!playgroundStatus" class="row">
      <div class="col-xl-6 col-md-12 mb-lg-0 p-0 pe-lg-3">
        <div class="card h-100 border">
          <div class="card-body">
            <div class="back-Ground-free-Select">
              <div
                class="palette-color-div"
                style="
                  display: flex;
                  flex-wrap: wrap;

                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  align-content: center;
                "
              >
                <div
                  style="
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    align-content: center;
                  "
                  v-if="apliedColorsPallette.length > 0"
                >
                  <div>
                    <button
                      class="btn btn-outline-dark border px-5"
                      :style="{
                        'text-transform': 'none',
                      }"
                      :disabled="loading"
                      @click="ColorpaletteBegin()"
                    >
                      Apply Pallette
                    </button>
                  </div>
                  <div
                    style="
                      display: flex;
                      flex-wrap: wrap;
                      padding: 10px;
                      min-height: 10px;
                      margin: 10px;
                      border: 2px solid #eeede9;
                      border-radius: 25px;
                      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
                        0px 2px 2px 0px rgba(0, 0, 0, 0.14),
                        0px 1px 5px 0px rgba(0, 0, 0, 0.12);
                    "
                  >
                    <div
                      style="
                        display: inline-block;
                        margin-right: 15px;
                        padding: 5px 0;
                      "
                      v-for="(color, index) in apliedColorsPallette"
                      :key="index"
                    >
                      <div style="position: relative">
                        <button
                          class="btn"
                          :style="{ 'background-color': color }"
                          @mousedown="startDragandDropTrendcolors(color)"
                          @click="changeSelectedpathsColorWithbutton()"
                        >
                          {{ color }}

                          <i
                            class="bg-light bi bi-x-circle"
                            style="
                              position: absolute;
                              top: -10px;
                              right: 0;
                              width: 0.1rem;
                              height: 0.1rem;
                            "
                            @click="removeColorfromApliedPalet(index)"
                          ></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex">
              <span class="mx-1 align-self-center">Colors</span>
              <button class="btn p-0">
                <input
                  class="mx-1 p-1"
                  type="color"
                  id="color"
                  name="favcolor"
                  ref="colorPickerAddPalette"
                  v-model="colorFreeSelect"
                  @blur="colorFreeSelectfromPicker"
                />
              </button>

              <div class="text-center w-100">
                <!-- <button class="btn p-0 px-3">
                                <i class="bi bi-arrow-counterclockwise mythIconLg" @click="undoSelection()"></i>
                            </button>-->
                <button
                  class="btn btn-outline-dark border p-0"
                  style="min-width: 40px"
                >
                  <span
                    class="text-decoration-underline mythIconMd bi bi-arrow-counterclockwise"
                    @click="resetSelection()"
                  ></span>
                </button>
                <!-- <button class="btn p-0 ps-3">
                                    <span class="text-decoration-underline mythIconMd" @click="UndoSelection()">Undo</span>
                                </button> -->
              </div>
            </div>
            <div class="row">
              <div class="col-4 d-flex align-items-center">
                <span>TPX</span>
              </div>
              <div class="col-8">
                <input
                  class="text-input1 form-control"
                  type="text"
                  v-model="searchTextTpx"
                  @input="searchTpx"
                  placeholder="Search TPX Code"
                />
                <ul
                  v-if="searchTextTpx"
                  class="tpx-div"
                  style="list-style-type: none"
                >
                  <li
                    v-bind:style="{ 'background-color': name }"
                    v-for="(name, value) in searchResultsTpx"
                    @click="selectColorTpx(name)"
                    :key="name"
                  >
                    {{ value }} : {{ name }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="row">
              <div class="col-4 d-flex align-items-center">
                <span>Pantone</span>
              </div>
              <div class="col-8">
                <input
                  class="text-input1 form-control"
                  type="text"
                  v-model="searchTextPant"
                  @input="searchPantone"
                  placeholder="Search Pantone Code"
                />
                <ul
                  v-if="searchTextPant"
                  class="tpx-div"
                  style="list-style-type: none"
                >
                  <li
                    v-bind:style="{ 'background-color': name }"
                    v-for="(name, value) in searchResultsPant"
                    @click="selectColorPantone(name)"
                    :key="name"
                  >
                    {{ value }} : {{ name }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="mt-2 mb-2">
              <div v-if="!userPaletteAddNameSelector" class="row">
                <div class="col-4 d-flex align-items-center">
                  <span>User Palettes</span>
                </div>

                <div class="col-4 d-flex align-items-center">
                  <button
                    class="btn btn-outline-dark border-0"
                    :style="{
                      'text-transform': 'none',
                    }"
                    :disabled="loading"
                    @click="AddUserPalette()"
                  >
                    <i class="bi bi-plus-lg mythIconLg"></i>
                  </button>
                </div>
              </div>
              <div v-else class="row">
                <div class="col-4 d-flex align-items-center">
                  <span>Create Palette</span>
                </div>

                <div class="col-4">
                  <input
                    class="form-control mt-0 w-100"
                    type="text"
                    v-model="newUserPalettename"
                    placeholder="Palette Name"
                  />
                </div>

                <div class="col-4 d-flex align-items-center">
                  <button
                    class="btn btn-outline-dark border-0"
                    :style="{
                      'text-transform': 'none',
                    }"
                    :disabled="loading"
                    @click="AddUserPalette()"
                  >
                    <i class="bi bi-patch-check mythIconLg"></i>
                  </button>
                </div>
              </div>

              <div>
                <ul class="nav nav-tabs" id="myTab2" role="tablist">
                  <li
                    class="nav-item"
                    role="presentation"
                    v-for="item in this.UserColorList"
                    :key="item"
                    :value="item"
                  >
                    <button
                      class="nav-link"
                      id="core-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#core"
                      type="button"
                      role="tab"
                      aria-controls="core"
                      aria-selected="true"
                      @click="UserPalettesetValue(item.palette_name)"
                    >
                      {{ item.palette_name }}
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="core-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#core"
                      type="button"
                      role="tab"
                      aria-controls="core"
                      aria-selected="true"
                      @click="EditUserPaletteSection()"
                    >
                      <i class="bi bi-gear mythIconLg"></i>
                    </button>
                  </li>
                </ul>
                <div
                  class="tab-content overflow-auto mh-75"
                  style="max-height: 300px"
                >
                  <div
                    class="tab-pane active"
                    id="core"
                    role="tabpanel"
                    aria-labelledby="core-tab"
                  >
                    <div
                      v-if="this.selectedUserPaletteName"
                      class="d-flex pt-3"
                    >
                      <div
                        style="
                          display: flex;
                          flex-wrap: wrap;
                          padding: 10px;
                          min-height: 10px;
                          margin: 10px;
                          border: 2px solid #eeede9;
                          border-radius: 25px;
                          box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
                            0px 2px 2px 0px rgba(0, 0, 0, 0.14),
                            0px 1px 5px 0px rgba(0, 0, 0, 0.12);
                        "
                      >
                        <div
                          v-if="userPaletteAddiconController == 'userpalettes'"
                          style="
                            display: inline-block;
                            margin-right: 10px;
                            padding: 5px 0;
                          "
                        >
                          <div
                            style="
                              display: inline-block;
                              margin-right: 10px;
                              padding: 5px 0;
                            "
                            v-for="(color, index) in paletteColorsValuesUser"
                            :key="index"
                          >
                            <button
                              class="btn"
                              @click="AddColortoselectedColors(color)"
                              :style="{ 'background-color': color }"
                            >
                              {{ color }}
                            </button>
                          </div>
                          <div>
                            <button class="btn p-0">
                              <i class="bi bi-plus-square mythIconLg"></i>
                              <input
                                class="mx-1 p-1"
                                type="color"
                                id="color"
                                name="favcolor"
                                ref="colorPickerAddPalette"
                                v-model="usercolorPaletteFreeSelect"
                                @blur="usercolorPalettefromPicker"
                              />
                            </button>
                          </div>
                        </div>

                        <div
                          v-if="
                            userPaletteAddiconController == 'EditUserPalettes'
                          "
                          style="
                            display: inline-block;
                            margin-right: 10px;
                            padding: 5px 0;
                          "
                        >
                          <div class="d-flex justify-content-between pb-3">
                            <span
                              class="w-100 align-self-center"
                              style="min-width: 100px"
                              >User Palette</span
                            >
                            <label for="PaletteList"></label>
                            <select
                              name="PaletteList"
                              id="PaletteList"
                              v-model="selectedUserPaletteName"
                              @change="EditPalettefromUserPalette()"
                            >
                              <option
                                v-for="item in this.UserColorList"
                                :key="item.palette_name"
                                :value="item.palette_name"
                              >
                                {{ item.palette_name }}
                              </option>
                            </select>
                          </div>
                          <div class="text-center w-100">
                            <button
                              class="btn btn-outline-dark border-0 px-4"
                              :style="{
                                'text-transform': 'none',
                              }"
                              :disabled="loading"
                              @click="EditPalettefromUserPalette()"
                            >
                              Edit
                            </button>
                            <button
                              class="btn btn-outline-dark border-0 px-4"
                              :style="{
                                'text-transform': 'none',
                              }"
                              :disabled="loading"
                              @click="removePalettefromUserPalette()"
                            >
                              <i class="bi bi-trash"></i>
                            </button>
                          </div>
                          <div
                            v-if="
                              userPaletteEditController &&
                              selectedUserPaletteName
                            "
                            style="
                              display: inline-block;
                              margin-right: 10px;
                              padding: 5px 0;
                            "
                          >
                            <div
                              style="
                                display: flex;
                                flex-wrap: wrap;
                                padding: 10px;
                                min-height: 10px;
                                margin: 10px;
                                border: 2px solid #eeede9;
                                border-radius: 25px;
                                box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
                                  0px 2px 2px 0px rgba(0, 0, 0, 0.14),
                                  0px 1px 5px 0px rgba(0, 0, 0, 0.12);
                              "
                            >
                              <div>
                                <div
                                  style="
                                    display: inline-block;
                                    margin-right: 10px;
                                    padding: 5px 0;
                                  "
                                  v-for="(
                                    color, index
                                  ) in paletteColorsValuesUserEdit"
                                  :key="index"
                                >
                                  <button
                                    class="btn"
                                    :style="{ 'background-color': color }"
                                  >
                                    {{ color }}
                                    <i
                                      class="bg-light bi bi-x-circle"
                                      style="
                                        position: relative;
                                        top: -10px;
                                        right: -15px;
                                        width: 0.1rem;
                                        height: 0.1rem;
                                      "
                                      @click="removeColorfromUserPalette(color)"
                                    ></i>
                                  </button>
                                </div>

                                <button class="btn p-0">
                                  <i class="bi bi-plus-square mythIconLg"></i>
                                  <input
                                    class="mx-1 p-1"
                                    type="color"
                                    id="color"
                                    name="favcolor"
                                    ref="colorPickerAddPalette"
                                    v-model="usercolorPaletteFreeSelect"
                                    @blur="usercolorPalettefromPicker"
                                  />
                                </button>
                              </div>
                              <div class="text-center w-100">
                                <button
                                  class="btn btn-outline-dark border px-5"
                                  :style="{
                                    'text-transform': 'none',
                                  }"
                                  :disabled="loading"
                                  @click="EditPalettefromUserPaletteApply()"
                                >
                                  <i class="bi bi-patch-check mythIconLg"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div>
                <div class="d-flex justify-content-between pb-3">
                  <span class="w-50 align-self-center">Trend Colors</span>
                  <label for="seasons"></label>
                  <select
                    name="seasons"
                    id="seasons"
                    v-model="paletteColorsSelectorName"
                    @change="ColorPaletteinput()"
                  >
                    <option
                      v-for="Names in this.trencolorNames"
                      :key="Names"
                      :value="Names"
                    >
                      {{ Names }}
                    </option>
                  </select>
                </div>
                <div class="trendClass" v-if="paletteColorsSelectorName">
                                              <div
                              style="
                                display: inline-block;
                                margin-right: 10px;
                                padding: 5px 0;
                              "
                              v-for="(
                                color, index
                              ) in paletteColorsSelectorValues"
                              :key="index"
                            >
                              <button
                                class="btn"
                                @click="AddColortoselectedColors(color)"
                                :style="{ 'background-color': color }"
                              >
                                {{ color }}
                              </button>
                            </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-6 col-md-12 p-0 mt-3 mt-xl-0">
        <div class="d-flex justify-content-center pngBG position-relative">
          <div class="position-absolute chooseBgColor">
            <input type="color" id="bgColor" name="bgColor" value="#000" />
          </div>

          <svg
            id="mySVG"
            ref="mySVG"
            :width="width"
            :height="height"
            xmlns="http://www.w3.org/2000/svg"
            :viewBox="viewBox"
            preserveAspectRatio="xMidYMid meet"
            :transform="`scale(${scaleFactor})`"
          >
            <g
              v-for="item in gTags"
              :key="item.id"
              :id="item.id"
              :style="item.style"
              :fill="item.fill"
              :class="{
                hover: item.id === hoveredPathIndex,
                selected: selectedPaths.includes(item),
                highlight: isSelected(item),
              }"
              @mouseover="hoverPath(item.id), mouseOver(item)"
              @mouseout="unhoverPath(item.id)"
              @mousedown="selectPath(item)"
              @mouseup="stopSlection()"
            >
              <g style="transform: none">
                <path
                  v-for="path in item.paths"
                  :key="path.id"
                  :id="path.id"
                  :d="path.d"
                ></path>
              </g>
            </g>
          </svg>
        </div>
      </div>
    </div>
    <FabricComponent
      v-show="playgroundStatus"
      :data="allData"
      :fileSrc="inputData"
      @testFunc="testFunc"
      :svgSuccess="this.svgSuccess"
      :UserColorList="this.UserColorList"
      @UserColorListAction="UserColorListAction"
      ref="fabricPlayground"
    />
  </div>
</template>

<script>
import colorSVG from "../../assets/js/vectorizor-svg";
import FabricComponent from "./FabricComponent.vue";

export default {
  name: "VectorizerApp",
  props: [
    "fileSrc",
    "ColoredFileSrc",
    "data",
    "svgSuccess",
    "vectorizerColor",
    "containerTypes",
    "UserColorList",
  ],
  emits: [
    "GetUserColorList",
    "UserColorListAction",
    "ChangeContainerType",
    "VectorizerApplyChangedImage",
    "downloadSVGvectorized",
    "GetSVGelm",
    "GetSVGcolors",
    "CreateSVGelm",
    "IsLoading",
    "ColorCountVectorizerAplied",
    "ChangeLoaderStatus",
  ],
  components: { FabricComponent },

  data() {
    return {
      svgSuccessChild: this.svgSuccess,
      allData: this.data,
      inputData: this.fileSrc,
      pathQuality: 100,
      senderPathQuality: 2,
      imageExist: false,
      loading: false,
      colorPallette: null,
      colorPalletteOriginal: null,
      showPicker: null,
      selectedColor: this.color,
      searchTextTpx: "",
      searchTextPant: "",
      searchResultsTpx: {},
      searchResultsPant: {},
      searchResultsContTpx: null,
      searchResultsContPant: null,
      tpxData: null,
      pantonaData: null,
      minX: 0,
      minY: 0,
      scaleFactor: 1,
      width: 800,
      height: 800,
      viewBox: "200 200 800 800",
      gTags: null,
      paths: [],
      originPathsColor: [],
      changedPaths: [],
      selectedPathIndex: null,
      hoveredPathIndex: null,
      selectedPaths: [],
      selectedPathsColor: "#f5f5f5",
      ContinueSelect: false,
      isChange: false,
      selectedBackgroundColor: "#ff0000",
      backgroundpaths: [],
      opacity: 0,
      inPageopacity: "00",
      Showedimage: null,
      trendcolors: null,
      colorChanger: null,
      allColorsGroup: null,
      selectedPaletteGroup: null,
      selectedtrendcolors: null,
      trencolorNames: null,
      trencolorValues: null,
      items: [],
      allColorsGroupCount: [],
      selecteGroupindex: null,
      batchSize: 100,
      renderedPaths: [],
      currentBatch: 0,
      paletteColorsSelector: null,
      originalTrendColors: null,
      paletteColorsValuesUser: [],
      paletteColorsValuesUserEdit: [],
      userPaletteAddNameSelector: false,
      paletteColorsSelectorName: null,
      apliedColorsPallette: [],
      apliedColorsPalletteforChange: [],
      trendColorsShades: null,
      freeSelectColor: null,
      freeSelectColorCont: null,
      showSearchbarsPantone: false,
      showSearchbarsTPX: false,
      colorFreeSelect: "#808080",
      colorFreeSelectshow: null,
      colorForDrag: null,
      selectedDragGroup: null,
      preventClick: false,
      preventClickColor: null,
      paletteColorsSelectorController: null,
      history: [],
      redoHistory: [],
      parentTop: 0,
      vectorizerColorCount: 3,
      normalle: true,
      VectorizerColors: null,
      visibleCount: 5,
      selectedCount: null,
      paletteColorsSelectorValues: null,
      appplyButtonControllerVect: false,
      userPaletteSelector: null,
      userPaletteAddiconController: "",
      userPaletteEditController: false,
      selectedUserPaletteName: null,
      usercolorPaletteFreeSelect: "#808080",
      newUserPalettename: null,
      EditAddcolorfromOutsidecontroller: false,
      userpaletteSelectorName: null,
      UserColorlisLenghtcontroller: false,
      selectedPaletteIndex: "#fff",
      playgroundStatus: false,
      tabName: "New Version",
      spinnerStatus: true,
    };
  },
  watch: {
    svgSuccess() {
      this.sleep(500).then(() => {
        this.renderSvg();
      });
    },
    playgroundStatus(val) {
      if (!val) {
        this.tabName = "New Version";
      } else {
        this.tabName = "Old Version";
      }
    },
  },
  computed: {
    visibleGroups() {
      if (this.data.dominantColors && this.data.dominantColors.length > 0) {
        return this.data.dominantColors.slice(0, this.visibleCount).reverse();
      } else {
        return [];
      }
    },
  },
  created() {
    this.isLoading(true);
    this.$emit("GetSVGcolors", this.fileSrc);
    this.isLoading(false);
  },
  mounted() {
    this.tpxData = require("@/assets/json/tpx.json");
    this.pantonaData = require("@/assets/json/pantone.json");
    this.trendcolors = require("@/assets/json/trendcolors.json");
    this.originalTrendColors = require("@/assets/json/trendcolorsOrigin.json");
    this.trendColorsShades = require("@/assets/json/trendcolorsShades.json");
    this.trencolorNames = this.trendcolors.names;
    this.trencolorValues = this.trendcolors.firstValues;
  },
  methods: {
    isLoading(_stat) {
      this.$emit("ChangeLoaderStatus", _stat);
    },
    selectCountnumber(number) {
      this.selectedCount = number;
    },
    handleCheckboxChange(event) {
      this.selectedPaletteIndex = parseInt(event.target.value);
    },
    showMore() {
      this.visibleCount += 5;
    },
    btnVectorizerlicked() {
      this.normalle = !this.normalle;
      this.triggerColorSegmentationSelectionYes();
    },
    UserPalettesetValue(paletteName) {
      this.userPaletteAddiconController = "userpalettes";
      this.selectedUserPaletteName = paletteName;
      this.paletteColorsValuesUser =
        this.getPaletteColorValuefromUserPalettes(paletteName);
    },
    getPaletteColorValuefromUserPalettes(name) {
      const palette = this.UserColorList.find(
        (palette) => palette.palette_name === name
      );
      return palette ? palette.colors : null;
    },
    AddUserPaletteSection() {
      this.userPaletteAddiconController = "addUserPalettes";
    },
    AddUserPalette() {
      if (this.newUserPalettename != null) {
        this.$emit(
          "UserColorListAction",
          this.newUserPalettename,
          [],
          "insert"
        );
        this.newUserPalettename = null;
        this.userpaletteSelectorName = this.newUserPalettename;
      }
      this.userPaletteAddNameSelector = !this.userPaletteAddNameSelector;
    },
    AddUserPaletteClicked() {
      this.userPaletteAddNameSelector = !this.userPaletteAddNameSelector;
    },
    EditUserPaletteSection() {
      this.userPaletteAddiconController = "EditUserPalettes";
    },
    EditPalettefromUserPalette() {
      this.paletteColorsValuesUserEdit =
        this.getPaletteColorValuefromUserPalettes(this.selectedUserPaletteName);
      this.userPaletteEditController = !this.userPaletteEditController;
      this.EditAddcolorfromOutsidecontroller =
        !this.EditAddcolorfromOutsidecontroller;
    },
    EditPalettefromUserPaletteApply() {
      this.userPaletteEditController = !this.userPaletteEditController;
      this.EditAddcolorfromOutsidecontroller = false;
      this.paletteColorsValuesUser = this.paletteColorsValuesUserEdit;
    },
    removePalettefromUserPalette() {
      this.$emit(
        "UserColorListAction",
        this.selectedUserPaletteName,
        [],
        "delete-palette"
      );
      this.selectedUserPaletteName = null;
    },
    usercolorPalettefromPicker() {
      // add color to existing palette
      if (this.usercolorPaletteFreeSelect != null) {
        const colorlist = [];
        // for i in range colorlist yapılcak buraya
        colorlist.push(this.usercolorPaletteFreeSelect);
        this.$emit(
          "UserColorListAction",
          this.selectedUserPaletteName,
          colorlist,
          "insert"
        );
      }
      const isInclude = this.paletteColorsValuesUserEdit.includes(
        this.usercolorPaletteFreeSelect
      );
      if (!isInclude) {
        this.paletteColorsValuesUserEdit.push(this.usercolorPaletteFreeSelect);
      }
      const isInclude2 = this.paletteColorsValuesUser.includes(
        this.usercolorPaletteFreeSelect
      );
      if (!isInclude2) {
        this.paletteColorsValuesUser.push(this.usercolorPaletteFreeSelect);
      }
    },
    removeColorfromUserPalette(color) {
      // remove color from existing color palette
      const colorlist = [];
      colorlist.push(color);
      this.$emit(
        "UserColorListAction",
        this.selectedUserPaletteName,
        colorlist,
        "delete-color"
      );

      const indexToRemove = this.paletteColorsValuesUserEdit.indexOf(color);

      if (indexToRemove !== -1) {
        this.paletteColorsValuesUserEdit.splice(indexToRemove, 1);
      }
    },
    rangeValueChanged() {
      this.imgOpacity = document.getElementById("slider-img-range").value / 100;
    },
    colorFreeSelectfromPicker() {
      if (this.EditAddcolorfromOutsidecontroller) {
        this.$emit(
          "UserColorListAction",
          this.selectedUserPaletteName,
          [this.colorFreeSelect],
          "insert"
        );
        const isInclude = this.paletteColorsValuesUserEdit.includes(
          this.colorFreeSelect
        );
        if (!isInclude) {
          this.paletteColorsValuesUserEdit.push(this.colorFreeSelect);
        }
      } else {
        this.colorFreeSelectshow = this.colorFreeSelect;
        const isInclude = this.apliedColorsPallette.includes(
          this.colorFreeSelectshow
        );
        if (!isInclude) {
          this.apliedColorsPallette.push(this.colorFreeSelectshow);
        }
      }
    },
    getPaletteColorValue(name) {
      const color = this.trendcolors.colors.find((c) => c.name === name);
      return color ? color.values : null;
    },
    getPaletteColorValuefromShades(name) {
      const color = this.trendColorsShades.colors.find(
        (c) => c.name === name && c.parent === this.paletteColorsSelectorName
      );
      return color ? color.values : null;
    },
    ColorPalettesetValue(name) {
      this.paletteColorsSelectorValues = this.getPaletteColorValuefromShades(name);
    },
    AddColortoselectedColors(color) {
      if (this.EditAddcolorfromOutsidecontroller) {
        this.$emit(
          "UserColorListAction",
          this.selectedUserPaletteName,
          [color],
          "insert"
        );
        const isInclude = this.paletteColorsValuesUserEdit.includes(color);
        if (!isInclude) {
          this.paletteColorsValuesUserEdit.push(color);
        }
      } else {
        const isInclude = this.apliedColorsPallette.includes(color);
        if (!isInclude) {
          this.apliedColorsPallette.push(color);
        }
      }
    },
    ColorPaletteinput() {
      this.paletteColorsSelector = this.getPaletteColorValue(
        this.paletteColorsSelectorName
      );
      this.paletteColorsSelectorController = this.paletteColorsSelector;
      this.ColorPalettesetValue("Core Colours");
    },
    removeColorfromApliedPalet(index) {
      this.apliedColorsPallette.splice(index, 1);
    },
    // yapılcak
    // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // l
    // // burasııı
    // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // l

    UndoSelection() {
      if (this.history.length >= 1) {
        const previousState = this.history[this.history.length - 1];
        this.paths.forEach((path) => {
          const res = previousState.undopaths.find(
            (item) => item.id === path.id
          );
          path.fill = res.fill;
        });

        const back = Object.values({
          ...previousState.selectedBackgroundColor,
        }).join("");
        this.selectedBackgroundColor = back;
        const op = Object.values({
          ...previousState.opacity,
        }).join("");
        this.opacity = parseInt(op === "" ? "0" : op);
        const currentState = this.history.pop();
        this.redoHistory.unshift(currentState);
      }
    },

    addToHistory() {
      const currentState = {
        undopaths: this.paths.map((path) => ({ ...path })),
        selectedBackgroundColor: { ...this.selectedBackgroundColor },
        opacity: { ...this.opacity },
        time: new Date().getTime(),
      };
      this.history.push(currentState);
      this.redoHistory = [];
    },

    // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // l
    // // burasııı
    // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // l
    // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // l
    // // olmuslar
    // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // l
    changeSelectedpathsColorWithbutton() {
      if (this.preventClick) {
        return;
      }
      this.apliedcolorapplySelectedpath();
    },
    apliedcolorapplySelectedpath() {
      const rgbVall = this.colorChanger.hexToRgb(this.colorForDrag);
      const rgbString = `rgb(${rgbVall.join(", ")})`;
      this.selectedPaths.forEach((selectedPath) => {
        selectedPath.fill = rgbString;
      });
    },
    startDragandDropTrendcolors(color) {
      this.colorForDrag = color;
      this.preventClick = true;
      document.addEventListener("mouseup", this.handleDrop);
    },
    handleDrop(event) {
      this.preventClick = false;
      const path = document.elementFromPoint(event.clientX, event.clientY);
      if (
        (path.tagName.toLowerCase() === "path" && path.hasAttribute("id")) ||
        (path.tagName.toLowerCase() === "g" && path.hasAttribute("id"))
      ) {
        const pathId = path.getAttribute("id");
        const gtagId = this.getItemIdByPathId(pathId);
        this.targetColorChange(this.colorForDrag, gtagId);
      }
      document.removeEventListener("mouseup", this.handleDrop);
    },
    targetColorChange(color, pathId) {
      this.selectedDragGroup = pathId;
      this.gTags = this.colorChanger.colorChangewithDrag(
        this.selectedDragGroup,
        this.gTags,
        [color]
      );
    },
    getItemIdByPathId(pathId) {
      for (const gTag of this.gTags) {
        const matchingPath = gTag.paths.find((path) => path.id === pathId);
        if (matchingPath) {
          return gTag.id;
        }
      }

      return null;
    },
    btnVectorizerApplyClicked() {
      const intermediateCanvas = document.createElement("canvas");
      const intermediateCtx = intermediateCanvas.getContext("2d");

      const svgContent = `<?xml version="1.0" encoding="UTF-8"?>
        <svg class="img-fluid" id="outputsvg" xmlns="http://www.w3.org/2000/svg" width="${
          this.width
        }" height="${this.height}" viewBox="${this.viewBox}">
        ${this.gTags
          .map(
            (g) => `<g fill="${g.fill}" style="${g.style}">
            ${g.paths
              .map((path) => `<path id="${path.id}" d="${path.d}"/>`)
              .join("\n")}
            </g>`
          )
          .join("\n")}
        </svg>`;

      const svgImage = new Image();
      svgImage.src =
        "data:image/svg+xml;charset=utf-8," + encodeURIComponent(svgContent);

      svgImage.onload = () => {};
      this.$nextTick(() => {
        intermediateCanvas.width = svgImage.width;
        intermediateCanvas.height = svgImage.height;

        intermediateCtx.drawImage(
          svgImage,
          0,
          0,
          svgImage.width,
          svgImage.height
        );

        const pngDataURL = intermediateCanvas.toDataURL();

        this.$emit("VectorizerApplyChangedImage", pngDataURL);
      });
    },
    DownlaodSVGvectorized() {
      this.$emit(
        "downloadSVGvectorized",
        this.viewBox,
        this.width,
        this.height,
        this.gTags
      );
    },
    btnBackClicked() {
      this.$emit("ChangeContainerType", this.containerTypes.Color);
    },
    sleep(time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
    ColorpaletteBegin() {
      this.apliedColorsPallette.forEach((item) => {
        const isInclude = this.apliedColorsPalletteforChange.includes(item);
        if (!isInclude) {
          this.apliedColorsPalletteforChange.push(item);
        }
      });
      this.selecteGroupindex = 0;
      this.gTags = this.colorChanger.useColorPalette(
        this.gTags,
        this.apliedColorsPalletteforChange
      );
      this.apliedColorsPalletteforChange = [];
      this.appplyButtonControllerVect = true;
    },
    resetSelection() {
      this.appplyButtonControllerVect = false;
      this.history = [];
      var res;
      this.gTags.forEach((gtag) => {
        res = this.originPathsColor.find((item) => item.id === gtag.id);
        gtag.fill = res.fill;
        gtag.highlighted = false;
      });
    },
    selectPath(path) {
      const selectedPathIndex = this.selectedPaths.indexOf(path);
      if (selectedPathIndex === -1) {
        this.selectedPaths.push(path);
        this.changedPaths.push(path);
      } else {
        this.selectedPaths.splice(selectedPathIndex, 1);
      }
      this.ContinueSelect = true;
    },
    mouseOver(path) {
      if (this.ContinueSelect) {
        const selectedPathIndex = this.selectedPaths.indexOf(path);
        if (selectedPathIndex === -1) {
          this.selectedPaths.push(path);
          this.changedPaths.push(path);
        } else {
          this.selectedPaths.splice(selectedPathIndex, 1);
        }
      }
    },

    stopSlection() {
      this.ContinueSelect = false;
    },
    isSelected(path) {
      return this.selectedPaths.indexOf(path) !== -1;
    },
    hoverPath(index) {
      this.hoveredPathIndex = index;
    },
    unhoverPath() {
      this.hoveredPathIndex = null;
    },
    triggerColorSegmentationSelectionYes() {
      this.redoHistory = [];
      this.history = [];
      this.originPathsColor = [];
      this.paths = [];
      this.renderedPaths = [];
      this.allColorsGroupCount = [];
      this.allColorsGroup = [];
      this.isLoading(true);
      this.colorChanger = new colorSVG();

      this.$emit("CreateSVGelm", this.fileSrc, this.selectedCount);
    },
    renderSvg() {
      this.viewBox = this.data.SVG.viewbox;
      this.gTags = this.data.SVG.gTags;
      this.width = this.data.SVG.width;
      this.height = this.data.SVG.height;
      this.selectedBackgroundColor = this.data.SVG.selected_background;
      this.originPathsColor = JSON.parse(JSON.stringify(this.data.SVG.gTags));
      this.isLoading(false);
    },
    testFunc(file, selCount) {
      this.redoHistory = [];
      this.history = [];
      this.originPathsColor = [];
      this.paths = [];
      this.renderedPaths = [];
      this.allColorsGroupCount = [];
      this.allColorsGroup = [];
      this.isLoading(true);
      this.colorChanger = new colorSVG();

      this.$emit("CreateSVGelm", file, selCount);
    },
    // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // l
    // // olmuslar
    // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // l

    searchTpx() {
      this.searchResultsTpx = {};
      const searchTextTpx = this.searchTextTpx.toLowerCase();
      for (let i = 0; i < this.tpxData.names.length; i++) {
        if (this.tpxData.names[i].toLowerCase().includes(searchTextTpx)) {
          this.searchResultsTpx[this.tpxData.names[i]] = this.tpxData.values[i];
          this.searchResultsContTpx = Object.keys(this.searchResultsTpx).length;
        }
      }
      const firstSevenKeys = Object.keys(this.searchResultsTpx).slice(0, 7);
      const firstSevenResults = {};
      firstSevenKeys.forEach((key) => {
        firstSevenResults[key] = this.searchResultsTpx[key];
      });
      this.searchResultsTpx = firstSevenResults;
    },
    selectColorTpx(value) {
      if (this.EditAddcolorfromOutsidecontroller) {
        this.$emit(
          "UserColorListAction",
          this.selectedUserPaletteName,
          [value],
          "insert"
        );
        const isInclude = this.paletteColorsValuesUserEdit.includes(value);
        if (!isInclude) {
          this.paletteColorsValuesUserEdit.push(value);
        }
      } else {
        const isInclude = this.apliedColorsPallette.includes(value);
        if (!isInclude) {
          this.apliedColorsPallette.push(value);
        }
      }
    },
    searchPantone() {
      this.searchResultsPant = {};
      const searchTextPant = this.searchTextPant.toLowerCase();
      for (let i = 0; i < this.pantonaData.names.length; i++) {
        if (this.pantonaData.names[i].toLowerCase().includes(searchTextPant)) {
          this.searchResultsPant[this.pantonaData.names[i]] =
            this.pantonaData.values[i];
          this.searchResultsContPant = Object.keys(
            this.searchResultsPant
          ).length;
        }
      }
      const firstSevenKeys = Object.keys(this.searchResultsPant).slice(0, 7);
      const firstSevenResults = {};
      firstSevenKeys.forEach((key) => {
        firstSevenResults[key] = this.searchResultsPant[key];
      });
      this.searchResultsPant = firstSevenResults;
    },
    selectColorPantone(value) {
      if (this.EditAddcolorfromOutsidecontroller) {
        this.$emit(
          "UserColorListAction",
          this.selectedUserPaletteName,
          [value],
          "insert"
        );
        const isInclude = this.paletteColorsValuesUserEdit.includes(value);
        if (!isInclude) {
          this.paletteColorsValuesUserEdit.push(value);
        }
      } else {
        const isInclude = this.apliedColorsPallette.includes(value);
        if (!isInclude) {
          this.apliedColorsPallette.push(value);
        }
      }
    },
    changePlaygroundStatus() {
      this.playgroundStatus = !this.playgroundStatus;
      if (!this.$refs.fabricPlayground.isPathAdded) {
        this.$refs.fabricPlayground.addPaths();
      }
    },
  },
};
</script>

<style>
.canvas-container {
  width: 100% !important;
}

.nav-link {
  color: gray;
}

.nav-link:active {
  color: black;
}

.c {
  width: 20px !important;
  height: 40px !important;
}

.pngBG {
  background-image: url("../../assets/img/transBG.jpg");
  background-repeat: repeat;
}

.chooseBgColor {
  top: 10px;
  left: 10px;
}

.highlight {
  stroke: #f00;
  stroke-width: 2px;
}

.hover {
  stroke: #ff0;
  stroke-width: 2px;
}

#bgColor {
  padding: 3px;
}

select {
  width: 100%;
  white-space: pre-wrap;
  text-overflow: ellipsis;
}

.pallete-radio {
  width: 410px;
  height: 100%;
  position: absolute;
  opacity: 0;
  z-index: 99;
}

.palette {
  position: relative;
  border: 1px solid #fff;
  border-radius: 50px;
  padding: 5px 20px;
  margin-bottom: 10px;
}

.version-button:hover {
  transform: scale(1.05);
}

.spinner-border {
  width: 50px;
  height: 50px;
}
</style>
