import { defineStore } from "pinia";

export const useUploadedImageStore = defineStore("uploadedImage", {
  state: () => {
    return {
        checkColorStep: false,
        uploadedImage: ""
    };
  },
  getters: {},
  actions: {
  },
});
