function colorSVG() {
  // const trendcolors = require("../json/trendcolors.json");
  function groupSvgFillsByColorDifference(paths, colorDifference) {
    const fillColors = [];
    paths.forEach((path) => {
      const fill = path.fill;
      if (fill) {
        fillColors.push(fill);
      }
    });

    const colorGroups = new Map();
    fillColors.forEach((color1, i) => {
      for (let j = i + 1; j < fillColors.length; j++) {
        const color2 = fillColors[j];
        if (colorDifferenceBetweenColors(color1, color2) <= colorDifference) {
          if (!colorGroups.has(i)) {
            colorGroups.set(i, []);
          }
          if (!colorGroups.has(j)) {
            colorGroups.set(j, []);
          }
          colorGroups.get(i).push(j);
          colorGroups.get(j).push(i);
        }
      }
    });

    const colorGroupsList = Array.from(getColorGroups(colorGroups), (group) =>
      Array.from(group)
    );
    return colorGroupsList;
  }

  function getColorGroups(colorGroups) {
    const visited = new Set();
    const groups = [];

    function dfs(node, newGroup) {
      visited.add(node);
      newGroup.add(node);
      for (const neighbor of colorGroups.get(node)) {
        if (!visited.has(neighbor)) {
          dfs(neighbor, newGroup);
        }
      }
    }

    for (const node of colorGroups.keys()) {
      if (!visited.has(node)) {
        const newGroup = new Set();
        dfs(node, newGroup);
        groups.push([...newGroup]);
      }
    }

    return groups;
  }

  function colorDifferenceBetweenColors(color1, color2) {
    // Convert the colors from hex strings to RGB tuples
    const r1 = parseInt(color1.substr(1, 2), 16);
    const g1 = parseInt(color1.substr(3, 2), 16);
    const b1 = parseInt(color1.substr(5, 2), 16);
    const r2 = parseInt(color2.substr(1, 2), 16);
    const g2 = parseInt(color2.substr(3, 2), 16);
    const b2 = parseInt(color2.substr(5, 2), 16);

    // Convert the RGB tuples to HSV tuples
    const [h1, s1, v1] = rgbToHsv(r1 / 255, g1 / 255, b1 / 255);
    const [h2, s2, v2] = rgbToHsv(r2 / 255, g2 / 255, b2 / 255);

    // Calculate the color difference using the HSV values
    const colorDifference =
      Math.abs(h1 - h2) * 360 +
      Math.abs(s1 - s2) * 100 +
      Math.abs(v1 - v2) * 100;
    return colorDifference;
  }

  function rgbToHsv(r, g, b) {
    const min = Math.min(r, g, b);
    const max = Math.max(r, g, b);
    const delta = max - min;
    let h, s;

    if (max === 0) {
      s = 0;
    } else {
      s = delta / max;
    }

    if (max === min) {
      h = 0;
    } else if (r === max) {
      h = (g - b) / delta;
    } else if (g === max) {
      h = 2 + (b - r) / delta;
    } else {
      h = 4 + (r - g) / delta;
    }

    h = h < 0 ? h + 6 : h;
    h = h / 6;

    return [h, s, max];
  }

  function closestColor(color, colors) {
    let diff = 100000;
    let best = null;
    for (let i = 0; i < colors.length; i++) {
      const d =
        Math.abs(color[0] - colors[i][0]) +
        Math.abs(color[1] - colors[i][1]) +
        Math.abs(color[2] - colors[i][2]);
      if (d < diff) {
        diff = d;
        best = i;
      }
    }
    return [colors[best], diff];
  }

  function rgbToHex(rgb) {
    return (
      "#" +
      ((1 << 24) + (rgb[0] << 16) + (rgb[1] << 8) + rgb[2])
        .toString(16)
        .slice(1)
    );
  }
  function hexToRgb(hex) {
    const r = parseInt(hex.substring(1, 3), 16);
    const g = parseInt(hex.substring(3, 5), 16);
    const b = parseInt(hex.substring(5, 7), 16);

    return [r, g, b];
  }
  function colorChange(
    colorGroups,
    selectedGroupindex,
    paths,
    trendColorPalettename,
    trendColorPalettevalues
  ) {
    // bordo
    // trend_color_palette = ["#87392d", "#873023", "#8a2819", "#8c1f0e", "#874136", "#a64738", "#612921"];
    // yesil
    // trend_color_palette = ["#34f70c", "#43fc1e", "#57f736", "#6fff52",  //                        "#81fa69", "#94f781",  "#a9f59a", "#c6f5bc", "#daf7d5", "#e7f7e4"]
    // kahve
    // trendColorPalette = [
    //   "#fbceb1",
    //   "#e2b99f",
    //   "#c9a58e",
    //   "#b0907c",
    //   "#977c6a",
    //   "#7e6759",
    //   "#645247",
    //   "#4b3e35",
    //   "#322923",
    //   "#191512",
    // ];
    // # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # j
    // trend_color_palette = ["#fbdc43", "#ecdc65", "#816c02", "#7c7c7c", "#d6d1b4", "#353123", "#e4d0a8",  "#ce9438", "#e2a43c", "#9c5e34"];

    // trend_color_palette = ["#c4ac84", "#9cd4eb", "#59482a", "#b4a46c", "#d1ccbc", "#444444"]
    // background için id 0 yap
    // trend_color_palette = ["#7E64A8"]
    // let trendColorPalette = getColorValues(trendColorPalettename, trendcolors);
    let trendColorPalette = trendColorPalettevalues;
    const trendColorPaletteRGB = trendColorPalette.map((color) =>
      hexToRgb(color)
    );

    for (const path of paths) {
      // if (is_contains(colorGroups, path.id, selectedGroupindex)) {
      const fillRGB = hexToRgb(path.fill);
      const [newColor] = closestColor(fillRGB, trendColorPaletteRGB);
      // buraya rgb to hexyaz
      const filledColor = rgbToHex(newColor);
      path.fill = filledColor;
    }
    return paths;
  }
  function is_contains(color_groups, id, group_index) {
    // Check if the given ID is present in the specified color group
    for (let i = 0; i < group_index.length; i++) {
      if (group_index[i] === id) {
        return true;
      }
    }
    return false;
  }
  function getColorValues(colorName, json) {
    const colorObj = json.colors.find((color) => color.name === colorName); // Find the object with the matching color name
    if (colorObj) {
      // If the color object is found
      return colorObj.values; // Return the corresponding color values
    }
    return null; // Return null if the color name is not found
  }
  function getShadesOfColor(hexColor) {
    // Remove the '#' symbol if it's included
    let originVal = hexColor;
    if (hexColor[0] === "#") {
      hexColor = hexColor.slice(1);
    }

    // Convert the hex color to RGB
    const red = parseInt(hexColor.slice(0, 2), 16);
    const green = parseInt(hexColor.slice(2, 4), 16);
    const blue = parseInt(hexColor.slice(4, 6), 16);

    // Calculate the shades
    const shades = [];
    for (let i = 1; i <= 5; i++) {
      const percent = i * 0.1;
      const shadeRed = Math.floor(red * (1 - percent));
      const shadeGreen = Math.floor(green * (1 - percent));
      const shadeBlue = Math.floor(blue * (1 - percent));
      const shadeHex = `#${(shadeRed < 16 ? "0" : "") + shadeRed.toString(16)}${
        (shadeGreen < 16 ? "0" : "") + shadeGreen.toString(16)
      }${(shadeBlue < 16 ? "0" : "") + shadeBlue.toString(16)}`;
      shades.unshift(shadeHex);
    }
    for (let i = 1; i <= 5; i++) {
      const percent = i * 0.1;
      const tintRed = Math.floor(red + (255 - red) * percent);
      const tintGreen = Math.floor(green + (255 - green) * percent);
      const tintBlue = Math.floor(blue + (255 - blue) * percent);
      const tintHex = `#${(tintRed < 16 ? "0" : "") + tintRed.toString(16)}${
        (tintGreen < 16 ? "0" : "") + tintGreen.toString(16)
      }${(tintBlue < 16 ? "0" : "") + tintBlue.toString(16)}`;
      shades.push(tintHex);
    }
    shades.push(originVal);
    return shades;
  }
  function colorChangewithDrag(
    colorGroups,
    selectedGroupindex,
    paths,
    ApliedColors
  ) {
    let trendColorPalette = ApliedColors;
    const trendColorPaletteRGB = trendColorPalette.map((color) =>
      hexToRgb(color)
    );

    for (const path of paths) {
      if (is_contains_forDrag(colorGroups, path.id, selectedGroupindex)) {
        const fillRGB = hexToRgb(path.fill);
        const [newColor] = closestColor(
          fillRGB,
          trendColorPaletteRGB
        );
        // buraya rgb to hexyaz
        const filledColor = rgbToHex(newColor);
        path.fill = filledColor;
      }
    }
    return paths;
  }
  function is_contains_forDrag(color_groups, id, group_index) {
    // Check if the given ID is present in the specified color group
    const group = color_groups[group_index];
    for (let i = 0; i < group.length; i++) {
      if (group[i] === id) {
        return true;
      }
    }
    return false;
  }
  function useColorPalette(
    paths,
    selectedGroupindex,
    trendColorPalettename,
    trendColorPalettevalues,
    difference
  ) {
    const allcolorGroups = groupSvgFillsByColorDifference(paths, difference);
    let coloredPaths = colorChange(
      allcolorGroups,
      selectedGroupindex,
      paths,
      trendColorPalettename,
      trendColorPalettevalues
    );
    return coloredPaths;
  }
  return {
    groupSvgFillsByColorDifference,
    getColorValues,
    getColorGroups,
    colorDifferenceBetweenColors,
    rgbToHsv,
    closestColor,
    rgbToHex,
    hexToRgb,
    colorChange,
    colorChangewithDrag,
    is_contains,
    getShadesOfColor,
    useColorPalette,
  };
}

export default colorSVG;
