<template class="bg-myth">
  <div>
    <button class="back-to-main-button" @click="backToDesign">
      <i class="bi bi-arrow-left"></i>Back to Main Page
    </button>
  </div>
  <div class="container">
    <div class="pageTitle"><h1>Frequently Asked Questions</h1></div>
    <div class="row justify-content-center">
      <div clas="col-8">
        <div class="accordion" id="accordionExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                Who owns the pattern produced by MYTH?
                <span class="arrow"></span>
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                MYTH transfers all intellectual property rights of the patterns
                downloaded by the Customer.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                Are there similarities among the patterns produced by MYTH?
                <span class="arrow"></span>
              </button>
            </h2>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <p>
                  Even if there are similarities between the patterns uploaded
                  by the Customer or requested by the Customer, MYTH creates
                  custom patterns for the user. MYTH commits that even in
                  similar operations, the similarity rate is technically 31.52
                  (PSNR - Ref.1).
                </p>
                <p>
                  MYTH ensures that the similarity rates of the patterns in its
                  library are kept within a maximum of 59.49% (SAM - Ref.2). It
                  proves that there is no similarity between the patterns by
                  mapping the similarities using the Spectral Angle Mapper (SAM)
                  method.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                How will you prove ownership of the pattern?
                <span class="arrow"></span>
              </button>
            </h2>
            <div
              id="collapseThree"
              class="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                MYTH provides a certificate of ownership with every pattern
                downloaded by the Customer, confirming that all rights and
                ownership of the downloaded pattern are transferred to the
                Customer.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFour">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                What is MYTH AI software?
                <span class="arrow"></span>
              </button>
            </h2>
            <div
              id="collapseFour"
              class="accordion-collapse collapse"
              aria-labelledby="headingFour"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                MYTH AI is an artificial intelligence-based program that
                facilitates pattern creation. It allows users to create new
                patterns by uploading similar images to the desired pattern or
                describing the pattern they want to create. Unlike programs like
                Photoshop or Adobe, MYTH AI does not retrieve similar patterns
                from a database but creates them instantly using artificial
                intelligence technology.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFive">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
              >
                What sets MYTH apart from applications like Shutterstock used
                for pattern inspiration?
                <span class="arrow"></span>
              </button>
            </h2>
            <div
              id="collapseFive"
              class="accordion-collapse collapse"
              aria-labelledby="headingFive"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                It's important to discuss the input and output story. There's a
                risk of recalling something previously seen and inadvertently
                creating something reminiscent of it in Adobe programs.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingSix">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSix"
                aria-expanded="false"
                aria-controls="collapseSix"
              >
                Are the patterns produced by MYTH similar?
                <span class="arrow"></span>
              </button>
            </h2>
            <div
              id="collapseSix"
              class="accordion-collapse collapse"
              aria-labelledby="headingSix"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                All patterns produced by MYTH are unique. Technically, the
                patterns are 0.0061035% different from each other. This
                uniqueness arises from each point in the computer-generated
                randomly, leading to the probability of the next latent space
                resembling any previous or subsequent points.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingSeven">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSeven"
                aria-expanded="false"
                aria-controls="collapseSeven"
              >
                What measures does MYTH take to ensure the uniqueness of the
                patterns produced?
                <span class="arrow"></span>
              </button>
            </h2>
            <div
              id="collapseSeven"
              class="accordion-collapse collapse"
              aria-labelledby="headingSeven"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                MYTH is a closed-source application, tailoring specific datasets
                for a purpose. It learns organically from each production or
                liked/downloaded designs. It doesn't generate a similar pattern
                to the downloaded design and provides an Ownership Certificate
                for the downloaded design.
              </div>
            </div>
          </div>

          <!-- Accordion Item #8 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingEight">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseEight"
                aria-expanded="false"
                aria-controls="collapseEight"
              >
                Does MYTH store the designs uploaded by the company?
                <span class="arrow"></span>
              </button>
            </h2>
            <div
              id="collapseEight"
              class="accordion-collapse collapse"
              aria-labelledby="headingEight"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                No. MYTH or third parties cannot see the uploaded design. MYTH
                only saves the downloaded designs among the generated designs.
                It saves the downloaded designs to create ownership certificates
                and never reproduce them.
              </div>
            </div>
          </div>

          <!-- Accordion Item #9 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingNine">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseNine"
                aria-expanded="false"
                aria-controls="collapseNine"
              >
                In what format do the AI designs come?
                <span class="arrow"></span>
              </button>
            </h2>
            <div
              id="collapseNine"
              class="accordion-collapse collapse"
              aria-labelledby="headingNine"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                The AI designs come in TIFF (pixel-based), SVG (vector-based),
                JPEG, and PNG formats. No special tech or converter technology
                is needed by factories to receive AI designs.
              </div>
            </div>
          </div>

          <!-- Accordion Item #10 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTen">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTen"
                aria-expanded="false"
                aria-controls="collapseTen"
              >
                What extra servers and storage space are needed to support AI
                design in the business?
                <span class="arrow"></span>
              </button>
            </h2>
            <div
              id="collapseTen"
              class="accordion-collapse collapse"
              aria-labelledby="headingTen"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                No extra server or storage space is needed. MYTH operates on
                Amazon Web Services, known as the world's most secure server. An
                internet connection is all that's required.
              </div>
            </div>
          </div>

          <!-- Accordion Item #11 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingEleven">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseEleven"
                aria-expanded="false"
                aria-controls="collapseEleven"
              >
                Is there any environmental impact using AI with additional
                processing speed and server space?
                <span class="arrow"></span>
              </button>
            </h2>
            <div
              id="collapseEleven"
              class="accordion-collapse collapse"
              aria-labelledby="headingEleven"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                Regarding speed and server space, there's no impact. However,
                increasing speed on AWS is possible at an additional cost. MYTH
                aims to eliminate waste in the design chain, reducing the carbon
                footprint of printing and pattern design processes by 65%.
              </div>
            </div>
          </div>
          <!-- Accordion Item #12 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwelve">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwelve"
                aria-expanded="false"
                aria-controls="collapseTwelve"
              >
                How labor-intensive is it to input data into AI?
                <span class="arrow"></span>
              </button>
            </h2>
            <div
              id="collapseTwelve"
              class="accordion-collapse collapse"
              aria-labelledby="collapseTwelve"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                Inputting data into AI involves running category-specific
                designs through prompts or uploading images. It takes about 3-4
                minutes per design.
              </div>
            </div>
          </div>
          <!-- Accordion Item #13 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingThirteen">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThirteen"
                aria-expanded="false"
                aria-controls="collapseThirteen"
              >
                What control does the designer have over AI design?
                <span class="arrow"></span>
              </button>
            </h2>
            <div
              id="collapseThirteen"
              class="accordion-collapse collapse"
              aria-labelledby="collapseThirteen"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                Designers can control AI design through prompts for effects,
                categories, colors, and every detail.
              </div>
            </div>
          </div>
          <!-- Accordion Item #14 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFourteen">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFourteen"
                aria-expanded="false"
                aria-controls="collapseFourteen"
              >
                Can AI understand the construction of a garment?
                <span class="arrow"></span>
              </button>
            </h2>
            <div
              id="collapseFourteen"
              class="accordion-collapse collapse"
              aria-labelledby="collapseFourteen"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                MYTH specializes in producing printing patterns but does not
                create initial cuts or patterns for clothing. It can convert
                designs into software for ready-to-wear clothing. Adjustments
                can be made to specific parts of the clothing using software
                like Browzwear/Clo3d.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    backToDesign() {
      this.$router.push("/");
    },
  },
};
</script>
<style scoped>
.pageTitle {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.accordion {
  margin-top: 100px;
  margin-bottom: 100px;
  --bs-accordion-btn-icon: none;
  --bs-accordion-btn-active-icon: none;
}
.accordion-item {
  margin: 25px;
  border: 1px solid #000;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.accordion-item button {
  position: relative;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}
.accordion-button:not(.collapsed) {
  background-color: #000;
  color: #fff;
}
.collapsed .arrow {
  position: absolute;
  top: 25px;
  right: 15px;
  width: 20px;
  height: 0;
  border-left: none;
  border-right: none;
  border-top: 2px solid black;
}
.accordion-button:hover {
  box-shadow: 5px 5px 4px gray;
}
.arrow {
  position: absolute;
  top: 19px;
  right: 15px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 12px solid #fff;
  transition: 0.3s;
}
button::selection {
  background: none;
}
button:focus {
  border-color: lightslategray;
  box-shadow: none;
}
</style>
