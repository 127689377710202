import MagicWand from "./magic-wand";
import paper from "paper";
function WandTool(parent, onDrawEnd, options) {
  let wand = new MagicWand();
  let colorThreshold = 15;
  let blurRadius = 5;
  let simplifyTolerant = 0;
  let simplifyCount = 30;
  let hatchLength = 4;
  let hatchOffset = 0;
  let imageInfo = null;
  let cacheInd = null;
  let mask = null;
  let oldMask = null;
  let downPoint = null;
  let allowDraw = false;
  let addMode = false;
  let currentThreshold = colorThreshold;
  let FinalPath = null;
  let pathOndraw = null;
  // document.onkeydown = onKeyDown;
  // document.onkeyup = onKeyUp;

  setInterval(function () {
    hatchTick();
  }, 300);

  function onRadiusChange(e) {
    blurRadius = e.target.value;
  }
  function startMagicWand(option) {
    let returnPath = initPath(Object.assign({}, options, option));
    FinalPath = returnPath;
    parent.onMouseDown = parent.onTouchStart = (e) => {
      if (e.event.button == 0) {
        allowDraw = true;
        addMode = e.event.ctrlKey;
        downPoint = getMousePosition(e);
        drawMask(downPoint.x, downPoint.y);
        // returnPath = drawWandPath(
        //   resultPath,
        //   imageInfo.width,
        //   imageInfo.height
        // );
        // if (onDrawEnd) onDrawEnd();
        // if (option && option.onDrawEnd) option.onDrawEnd(returnPath);
        // // return;
        // returnPath = drawWandPath(
        //   borderOfShape,
        //   imageInfo.width,
        //   imageInfo.height,
        //   returnPath,
        //   false,
        //   option
        // );
        FinalPath = returnPath;
      } else {
        allowDraw = false;
        addMode = false;
      }
    };
    parent.onMouseDrag = parent.onTouchMove = (e) => {
      if (allowDraw) {
        var p = getMousePosition(e);
        if (p.x != downPoint.x || p.y != downPoint.y) {
          var dx = p.x - downPoint.x,
            dy = p.y - downPoint.y,
            len = Math.sqrt(dx * dx + dy * dy),
            adx = Math.abs(dx),
            ady = Math.abs(dy),
            sign = adx > ady ? dx / adx : dy / ady;
          sign = sign < 0 ? sign / 5 : sign / 3;
          var thres = Math.min(
            Math.max(colorThreshold + Math.floor(sign * len), 1),
            255
          );
          thres = Math.min(colorThreshold + Math.floor(len / 3), 255);
          if (thres != currentThreshold) {
            currentThreshold = thres;

            drawMask(p.x, p.y);
          }
        }
      }
    };
    parent.onMouseUp = function () {
      allowDraw = false;
      addMode = false;
      oldMask = null;
      currentThreshold = colorThreshold;
      FinalPath = trace(option);
      if (onDrawEnd) onDrawEnd();
      if (option && option.onDrawEnd) option.onDrawEnd(FinalPath);
    };
  }
  // function onKeyDown(e) {
  //   if (e.keyCode == 17)
  //     document.getElementById("canvas1").classList.add("add-mode");
  // }
  // function onKeyUp(e) {
  //   if (e.keyCode == 17)
  //     document.getElementById("canvas1").classList.remove("add-mode");
  // }

  function initPath(option) {
    let path = new paper.Path();
    path.option = option;
    path.name = option.name;
    path.closed = false;
    path.selected = option.selected || true;
    path.locked = option.locked || false;
    path.strokeColor = option.strokeColor;
    path.selectedColor = option.selectedColor;
    path.fillColor = option.fillColor;
    return path;
  }
  function initCanvas(img, pathOndrawCrop, rasterInfo) {
    pathOndraw = pathOndrawCrop;
    let originalWidth, originalHeight;
    pathOndraw.fillColor = "rgba(0, 0, 0, 0)";
    originalWidth = img.width;
    originalHeight = img.height;
    if (img.width > 1000 || img.height > 1000) {
      img.width = rasterInfo.width;
      img.height = rasterInfo.height;
      Math.min(1000 / originalWidth, 1000 / originalHeight, 1);
    }

    var cvs = document.createElement("canvas");
    cvs.width = img.width;
    cvs.height = img.height;
    imageInfo = {
      width: img.width,
      height: img.height,
      context: cvs.getContext("2d"),
    };
    mask = null;
    var tempCtx = document.createElement("canvas").getContext("2d");
    tempCtx.canvas.width = imageInfo.width;
    tempCtx.canvas.height = imageInfo.height;
    tempCtx.drawImage(
      img,
      rasterInfo.x,
      rasterInfo.y,
      rasterInfo.width,
      rasterInfo.height
    );
    imageInfo.data = tempCtx.getImageData(
      0,
      0,
      imageInfo.width,
      imageInfo.height
    );
    // console.log(
    //   "initcanvas Valuesss: ",
    //   img.width,
    //   img.height,
    //   imageInfo.width,
    //   imageInfo.height,
    //   tempCtx.canvas.width,
    //   tempCtx.canvas.height,
    //   cvs.width,
    //   cvs.height,
    //   scale,
    //   originalWidth,
    //   originalHeight,
    //   rasterInfo
    // );
  }
  function getMousePosition(e) {
    var p = e.event.target.getBoundingClientRect(),
      x = Math.round((e.event.clientX || e.event.pageX) - p.left),
      y = Math.round((e.event.clientY || e.event.pageY) - p.top);
    return { x: x, y: y };
  }

  function drawMask(x, y) {
    if (!imageInfo) return;

    var image = {
      data: imageInfo.data.data,
      width: imageInfo.width,
      height: imageInfo.height,
      bytes: 4,
    };
    if (addMode && !oldMask) {
      oldMask = mask;
    }

    let old = oldMask ? oldMask.data : null;

    mask = wand.floodFillWithBorders(image, x, y, currentThreshold, old);

    if (mask) mask = wand.gaussBlurOnlyBorder(mask, blurRadius, old);

    if (addMode && oldMask) {
      mask = mask ? concatMasks(mask, oldMask) : oldMask;
    }

    drawBorder(false);
  }
  function hatchTick() {
    hatchOffset = (hatchOffset + 1) % (hatchLength * 2);
    drawBorder(true);
  }
  function drawBorder(noBorder) {
    if (!mask) return;
    // var pathOndraw = initPath(Object.assign({}, options, option));
    if (pathOndraw) {
      pathOndraw.removeSegments();
    }
    var point;
    var x,
      y,
      i,
      j,
      w = imageInfo.width,
      h = imageInfo.height,
      ctx = imageInfo.context,
      imgData = ctx.createImageData(w, h),
        borderOfShape = [];
    if (!noBorder) cacheInd = wand.getBorderIndices(mask);

    ctx.clearRect(0, 0, w, h);

    var len = cacheInd.length;
    for (j = 0; j < len; j = j + 10) {
      i = cacheInd[j];
      x = i % w; // calc x by index
      y = (i - x) / w; // calc y by index
      // k = (y * w + x) * 4;
      point = new paper.Point(x, y);
      borderOfShape.push(point);
      // if ((x + y + hatchOffset) % (hatchLength * 2) < hatchLength) {
      //   // detect hatch color
      //   borderOfShape.push(point);
      //   // if (j == 0) {
      //   //   pathOndraw.add(point);
      //   // } else {
      //   //   pathOndraw.add(point);
      //   // }
      //   res[k + 3] = 255; // black, change only alpha
      // } else {
      //   res[k] = 255; // white
      //   res[k + 1] = 255;
      //   res[k + 2] = 255;
      //   res[k + 3] = 255;
      // }
    }
    drawWandPath(borderOfShape);
    ctx.putImageData(imgData, 0, 0);
    // console.log("last image data:", imgData);
  }
  function drawWandPath(pathData) {
    let borderOnly = [];
    let visited = new Set();
    let currentPoint = pathData[0];
    visited.add(currentPoint);

    while (visited.size < pathData.length) {
      let minDistance = Infinity;
      let nextPoint;

      for (let i = 0; i < pathData.length; i++) {
        if (visited.has(pathData[i])) {
          continue;
        }

        let distance = currentPoint.getDistance(pathData[i]);

        if (distance < minDistance) {
          minDistance = distance;
          nextPoint = pathData[i];
        }
      }

      visited.add(nextPoint);
      currentPoint = nextPoint;
      borderOnly.push(currentPoint);
    }

    for (let i = 0; i < borderOnly.length; i++) {
      if (i === 0) {
        pathOndraw.moveTo(borderOnly[i]);
      } else {
        pathOndraw.lineTo(borderOnly[i]);
      }
    }
  }
  function trace(option) {
    var path = initPath(Object.assign({}, options, option));

    var cs = wand.traceContours(mask);
    cs = wand.simplifyContours(cs, simplifyTolerant, simplifyCount);

    mask = null;

    // draw contours
    var ctx = imageInfo.context;
    ctx.clearRect(0, 0, imageInfo.width, imageInfo.height);
    //inner
    ctx.beginPath();
    for (var i = 0; i < cs.length; i++) {
      if (!cs[i].inner) continue;
      var ps = cs[i].points;
      ctx.moveTo(ps[0].x, ps[0].y);
      path.removeSegment(ps[0].x, ps[0].y);
      for (var j = 1; j < ps.length; j++) {
        ctx.lineTo(ps[j].x, ps[j].y);
        path.removeSegment(ps[j].x, ps[j].y);
      }
    }
    ctx.strokeStyle = "red";
    ctx.stroke();
    //outer
    ctx.beginPath();
    for (i = 0; i < cs.length; i++) {
      if (cs[i].inner) continue;
      ps = cs[i].points;
      ctx.moveTo(ps[0].x, ps[0].y);
      path.moveTo(ps[0].x, ps[0].y);
      for (j = 1; j < ps.length; j++) {
        ctx.lineTo(ps[j].x, ps[j].y);
        path.lineTo(ps[j].x, ps[j].y);
      }
    }
    ctx.strokeStyle = "blue";
    ctx.stroke();
    pathOndraw.remove();
    path.closed = true;
    return path;
  }
  function paint(color, alpha) {
    if (!mask) return;

    var rgba = hexToRgb(color, alpha);

    var x,
      y,
      data = mask.data,
      bounds = mask.bounds,
      maskW = mask.width,
      w = imageInfo.width,
      h = imageInfo.height,
      ctx = imageInfo.context,
      imgData = ctx.createImageData(w, h),
      res = imgData.data;

    for (y = bounds.minY; y <= bounds.maxY; y++) {
      for (x = bounds.minX; x <= bounds.maxX; x++) {
        if (data[y * maskW + x] == 0) continue;
        let k = (y * w + x) * 4;
        res[k] = rgba[0];
        res[k + 1] = rgba[1];
        res[k + 2] = rgba[2];
        res[k + 3] = rgba[3];
      }
    }

    mask = null;

    ctx.putImageData(imgData, 0, 0);
  }
  function hexToRgb(hex, alpha) {
    var int = parseInt(hex, 16);
    var r = (int >> 16) & 255;
    var g = (int >> 8) & 255;
    var b = int & 255;

    return [r, g, b, Math.round(alpha * 255)];
  }
  function concatMasks(mask, old) {
    let data1 = old.data,
      data2 = mask.data,
      w1 = old.width,
      w2 = mask.width,
      b1 = old.bounds,
      b2 = mask.bounds,
      b = {
        // bounds for new mask
        minX: Math.min(b1.minX, b2.minX),
        minY: Math.min(b1.minY, b2.minY),
        maxX: Math.max(b1.maxX, b2.maxX),
        maxY: Math.max(b1.maxY, b2.maxY),
      },
      w = old.width, // size for new mask
      h = old.height,
      i,
      j,
      k,
      k1,
      k2,
      len;

    let result = new Uint8Array(w * h);

    // copy all old mask
    len = b1.maxX - b1.minX + 1;
    i = b1.minY * w + b1.minX;
    k1 = b1.minY * w1 + b1.minX;
    k2 = b1.maxY * w1 + b1.minX + 1;
    // walk through rows (Y)
    for (k = k1; k < k2; k += w1) {
      result.set(data1.subarray(k, k + len), i); // copy row
      i += w;
    }

    // copy new mask (only "black" pixels)
    len = b2.maxX - b2.minX + 1;
    i = b2.minY * w + b2.minX;
    k1 = b2.minY * w2 + b2.minX;
    k2 = b2.maxY * w2 + b2.minX + 1;
    // walk through rows (Y)
    for (k = k1; k < k2; k += w2) {
      // walk through cols (X)
      for (j = 0; j < len; j++) {
        if (data2[k + j] === 1) result[i + j] = 1;
      }
      i += w;
    }

    return {
      data: result,
      width: w,
      height: h,
      bounds: b,
    };
  }
  return {
    paint,
    trace,
    initCanvas,
    onRadiusChange,
    startMagicWand,
  };
}
export default WandTool;
