import axios from "axios";
import { defineStore } from "pinia";

export const useLibraryStore = defineStore("library", {
  state: () => {
    return {};
  },
  getters: {},
  actions: {
    async fetchLabelDataRequest() {
      try {
        const url = "/library/image-library/labels";
        const response = await axios.get(url).then();
        return response;
      } catch (error) {
        console.error("Error fetching label data: ", error);
        throw error;
      }
    },
    async fetchImagesDataRequest(payload) {
      try {
        const url = "admin/library_management/images";
        const response = await axios.post(url, payload).then();
        return response;
      } catch (error) {
        console.error("Error while fetching images data: ", error);
        throw error;
      }
    },
    async uploadImageRequest(payload) {
      try {
        const url = "admin/library_management/upload_image";
        const response = await axios.post(url, payload).then();
        return response;
      } catch (error) {
        console.error("Error while uploading image data: ", error);
        throw error;
      }
    },
    async createLabelRequest(payload) {
      try {
        const url = "admin/library_management/create_label";
        const response = await axios.post(url, payload).then();
        return response;
      } catch (error) {
        console.error("Error while creating label: ", error);
      }
    },
    async deleteLabelRequest(payload) {
      try {
        const url = "admin/library_management/delete-label/<label_id>";
        const response = await axios.delete(url, payload).then();
        return response;
      } catch (error) {
        console.error("Error while creating label: ", error);
        throw error;
      }
    },
    async deleteLibraryItemRequest(image_id) {
      try {
        const url = `admin/library_management/delete-library-item/${image_id}`;
        const response = await axios.delete(url).then();
        return response;
      } catch (error) {
        console.error("Error while deleting image: ", error);
        throw error;
      }
    },
    async getGalleryLength(payload) {
      try {
        const url = "/library/get-library-length";
        const response = await axios.post(url,payload);
        return response;
      } catch (error) {
        console.error("Error get gallery length:", error);
        throw error;
      }
    },
  },
});
