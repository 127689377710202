import axios from "axios";
import { defineStore } from "pinia";

export const useGeneratedImageStore = defineStore("generateImage", {
  state: () => {
    return {};
  },
  getters: {},
  actions: {
    async getImage(payload) {
      try {
        const url = "/ai_prediction/text-to-image";
        const response = await axios.post(url, payload);
        return response;
      } catch (error) {
        console.error("Error generating image from text: ", error);
        throw error;
      }
    },
    async getFromTextAndImage(payload) {
      try {
        const url = "/ai_prediction/text-and-image-to-image";
        const response = await axios.post(url, payload);
        return response;
      } catch (error) {
        console.error("Error generating image from image and text: ", error);
        throw error;
      }
    },
    async getFromMultiImage(payload) {
      try {
        const url = "/ai_prediction/multi-image-to-image";
        const response = await axios.post(url, payload);
        return response;
      } catch (error) {
        console.error("Error generating image from multi image: ", error);
        throw error;
      }
    },
    async generateSimilar(payload) {
      try {
        const url = "/ai_prediction/generate-similar";
        const response = await axios.post(url, payload);
        return response;
      } catch (error) {
        console.error("Error generating similar: ", error);
        throw error;
      }
    },
    async generateSimilarImage(payload) {
      try {
        const url = "/ai_prediction/generate-similar-image";
        const response = await axios.post(url, payload);
        return response;
      } catch (error) {
        console.error("Error generating similar image: ", error);
        throw error;
      }
    },
    async upscaleImage(payload) {
      try {
        const url = "/ai_prediction/upscale";
        const response = await axios.post(url, payload);
        return response;
      } catch (error) {
        console.error("Error generating similar image: ", error);
        throw error;
      }
    },
  },
});
