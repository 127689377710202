import axios from "axios";
import { defineStore } from "pinia";

export const useDownloadRightStore = defineStore("downloadRight", {
  state: () => {
    return { downloadRight: 0 };
  },
  getters: {
  },
  actions: {
    async fetchDownloadRight() {
      try {
        const url = "/admin/company_management/available-downloads";

        await axios
          .get(url, {
            withCredentials: true,
          })
          .then((response) => {
            this.downloadRight = response.data.data.available_downloads;
          });
      } catch (error) {
        console.error("Error fetching download right:", error);
        throw error;
      }
    },
  },
});
